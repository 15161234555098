export const sanofiSites = [
  {
    site: `Amsterdam`,
    address_1: `Paasheuvelweg 25A`,
    address_2: ``,
    city: `Amsterdam`,
    state: ``,
    zip: `1105`,
    country: `Netherlands`,
  },
  {
    site: `Amunix/South San Francisco`,
    address_1: `2 Tower Pl #1100`,
    address_2: ``,
    city: `South San Francisco`,
    state: `CA`,
    zip: `94080`,
    country: `United States`,
  },
  {
    site: `Beijing`,
    address_1: `7 Xingsheng St, Daxing District`,
    address_2: ``,
    city: `Beijing`,
    state: ``,
    zip: `102676`,
    country: `China`,
  },
  {
    site: `Bridgewater`,
    address_1: `55 Corporate Drive`,
    address_2: ``,
    city: `Bridgewater`,
    state: `NJ`,
    zip: `08807`,
    country: `United States`,
  },
  {
    site: `Cambridge Crossing 350`,
    address_1: `350 Water St`,
    address_2: ``,
    city: `Cambridge`,
    state: `MA`,
    zip: `02141`,
    country: `United States`,
  },
  {
    site: `Cambridge Crossing 450`,
    address_1: `450 Water St`,
    address_2: ``,
    city: `Cambridge`,
    state: `MA`,
    zip: `02141`,
    country: `United States`,
  },
  {
    site: `Cambridge 640`,
    address_1: `640 Memorial Drive`,
    address_2: ``,
    city: `Cambridge`,
    state: `MA`,
    zip: `02141`,
    country: `United States`,
  },
  {
    site: `Chilly Mazarin`,
    address_1: `1 Avenue Pierre Brossolette`,
    address_2: ``,
    city: `Chilly Mazarin`,
    state: ``,
    zip: `91380`,
    country: `France`,
  },
  {
    site: `Framingham 49`,
    address_1: `49 New York Avenue`,
    address_2: ``,
    city: `Framingham`,
    state: `MA`,
    zip: `01701`,
    country: `United States`,
  },
  {
    site: `Framingham 1`,
    address_1: `1 The Mountain Rd`,
    address_2: ``,
    city: `Framingham`,
    state: 'MA',
    zip: `01701`,
    country: `United States`,
  },
  {
    site: `Frankfurt`,
    address_1: `Industriepark Hoechst`,
    address_2: ``,
    city: `Frankfurt`,
    state: `Hessen`,
    zip: `65926`,
    country: `Germany`,
  },
  {
    site: `Geel`,
    address_1: `Cipalstraat 8`,
    address_2: ``,
    city: `Geel`,
    state: `Antwerpen`,
    zip: `2440`,
    country: `Belgium`,
  },
  {
    site: `Ghent`,
    address_1: `Technologiepark 21A`,
    address_2: ``,
    city: `Zwijnaarde`,
    state: `Oost-Vlaanderen`,
    zip: `9052`,
    country: `Belgium`,
  },
  {
    site: `La Jolla`,
    address_1: `11099 N Torrey Pines Rd`,
    address_2: ``,
    city: `La Jolla`,
    state: `CA`,
    zip: `92037`,
    country: `United States`,
  },
  {
    site: `Le Trait`,
    address_1: `1051 Boulevaard Industriel`,
    address_2: ``,
    city: `Le Trait`,
    state: ``,
    zip: `76580`,
    country: `France`,
  },
  {
    site: `Marcy l' Etoile`,
    address_1: `1019 Av. Marcel`,
    address_2: ``,
    city: `Marcy l'Etoile`,
    state: ``,
    zip: `69280`,
    country: `France`,
  },
  {
    site: `Montpellier`,
    address_1: `371, avenue Pr Blayac`,
    address_2: ``,
    city: `Montpellier`,
    state: `Hérault`,
    zip: `34184`,
    country: `France`,
  },
  {
    site: `Orlando`,
    address_1: `2501 discovery drive ste 300`,
    address_2: ``,
    city: `Orlando`,
    state: `FL`,
    zip: `32826`,
    country: `United States`,
  },
  {
    site: `Neuville sur Saône`,
    address_1: `31 Quai Armand Barbès`,
    address_2: ``,
    city: `Neuville-sur-Saone`,
    state: ``,
    zip: `69250`,
    country: `France`,
  },
  {
    site: `South San Francisco`,
    address_1: `2 Tower Pl #1100`,
    address_2: ``,
    city: `South San Francisco`,
    state: `CA`,
    zip: `94080`,
    country: `United States`,
  },
  {
    site: `Swiftwater`,
    address_1: `1 Discovery Dr`,
    address_2: ``,
    city: `Swiftwater`,
    state: `PA`,
    zip: `18307`,
    country: `United States`,
  },
  {
    site: `Suzhou`,
    address_1: ``,
    address_2: ``,
    city: `Jiangsu`,
    state: ``,
    zip: ``,
    country: `China`,
  },
  {
    site: `Toronto`,
    address_1: `1755 Steeles Ave West`,
    address_2: ``,
    city: `Toronto`,
    state: `ON`,
    zip: `M2R 3T4`,
    country: `Canada`,
  },
  {
    site: `Vitry`,
    address_1: `13 quai Jules Guesde`,
    address_2: ``,
    city: `Vitry Sur Seine`,
    state: `Île-de-France`,
    zip: `94400`,
    country: `France`,
  },
  {
    site: `Waltham 225`,
    address_1: `225 Second Avenue`,
    address_2: ``,
    city: `Waltham`,
    state: `MA`,
    zip: `02451`,
    country: `United States`,
  },
  {
    site: `Waltham 211`,
    address_1: `211 Second Avenue`,
    address_2: ``,
    city: `Waltham`,
    state: `MA`,
    zip: `02451`,
    country: `United States`,
  },
  {
    site: `Waltham 200`,
    address_1: `200 West St`,
    address_2: ``,
    city: `Waltham`,
    state: `MA`,
    zip: `02451`,
    country: `United States`,
  },
  {
    site: `Waltham 153`,
    address_1: `153 2nd Avenue`,
    address_2: ``,
    city: `Waltham`,
    state: `MA`,
    zip: `02451`,
    country: `United States`,
  },
  {
    site: `Waterford`,
    address_1: `IDA Industrial Park`,
    address_2: ``,
    city: `Waterford`,
    state: ``,
    zip: `X91TP27`,
    country: `Ireland`,
  },
  {
    site: `Synthorx`,
    address_1: `11099 N Torrey Pines Rd`,
    address_2: ``,
    city: `La Jolla`,
    state: `CA`,
    zip: `92037`,
    country: `United States`,
  },
  {
    site: 'Other',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
];
