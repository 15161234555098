import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getChatWindow } from '../Store/Reducers/twilio';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2),
    },
  },
}));

const SimpleDialog = ({
  open,
  header,
  children,
  footer,
  handleClose,
  style,
  disableFullscreen,
}) => {
  const classes = useStyles();
  const chatWindow = useSelector(getChatWindow);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  // console.log(
  //   '🚀 ~ file: DialogWrapper.js ~ line 23 ~ SimpleDialog ~ chatWindow',
  //   chatWindow
  // );

  return (
    <Dialog
      className={classes.dialog}
      aria-labelledby="simple-dialog-title"
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={(event) => handleClose(event)}
      fullScreen={!disableFullscreen && fullScreen}
      // TODO: Look for Chat Redux and change Margin Right if Open
      disableEnforceFocus
      style={{ ...style }}
      // PaperProps={{ style: { marginRight: chatWindow ? 340 : 0 } }}
    >
      <div className={classes.paper}>
        {header}
        {children}
        {footer}
      </div>
    </Dialog>
  );
};

export default SimpleDialog;