import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Chip,
  Divider,
  Button,
} from '@material-ui/core';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EventHorizonAbout(props) {
  const classes = useStyles();
  const event = useSelector((state) => state.event.event);

  let enterVirtualEnvironment = (
    <>
      <Button
        variant="contained"
        color="primary"
        // component={Link}
        // to={'/abstractsubmission'}
        size="large"
        onClick={() =>
          window.open('https://sasvirtual.planetconnect.com/', '_self')
        }
      >
        Enter EventHorizon, the Virtual Environments
      </Button>
      <br />
      <br />
    </>
  );

  return (
    <>
      <Typography variant="h4">EventHorizon</Typography>

      <br />
      <Typography variant="body1">
        The EventHorizon Virtual Environment component will open approximately
        one week before the event begins. This environment will provide access
        to talks, networking and other assets.
      </Typography>
    </>
  );
}
