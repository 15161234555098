export const merckSites = [
  {
    site: 'Boston',
    address_1: '33 Avenue Louis Pasteur',
    address_2: '',
    city: 'Boston',
    state: 'MA',
    zip: '02115-5727',
    country: 'United States',
  },
  {
    site: 'Branchburg',
    address_1: '3070 Route 22 West',
    address_2: '',
    city: 'Branchburg',
    state: 'NJ',
    zip: '08876',
    country: 'United States',
  },
  {
    site: 'Cambridge',
    address_1: '320 Bent Street, 4th Floor',
    address_2: '',
    city: 'Cambridge',
    state: 'MA',
    zip: '02141',
    country: 'United States',
  },
  {
    site: 'Cherokee',
    address_1: 'Cherokee Pharmaceuticals',
    address_2: '100 Avenue C, PO Box 367',
    city: 'Riverside',
    state: 'PA',
    zip: '17868',
    country: 'United States',
  },
  {
    site: 'Durham',
    address_1: 'The Maurice R. Hilleman Center for Vaccine Manufacturing',
    address_2: '5325 Old Oxford Road',
    city: 'Durham',
    state: 'NC',
    zip: '27712',
    country: 'United States',
  },
  {
    site: 'Elkton',
    address_1: '2778 South Eastside Highway',
    address_2: '',
    city: 'Elkton',
    state: 'VA',
    zip: '22827',
    country: 'United States',
  },
  {
    site: 'France',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  {
    site: 'Ireland',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  {
    site: 'Kenilworth',
    address_1: '2015 Galloping Hill Rd',
    address_2: '',
    city: 'Kenilworth',
    state: 'NJ',
    zip: '07033',
    country: 'United States',
  },
  {
    site: 'Las Piedras',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  {
    site: 'London',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  {
    site: 'Madison',
    address_1: '2 Giralda Farms',
    address_2: '',
    city: 'Madison',
    state: 'NJ',
    zip: '07940',
    country: 'United States',
  },
  {
    site: 'Netherlands',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  // removed per Amy Aslamkhan
  // {
  //   site: 'Palo Alto',
  //   address_1: '901 S California Ave',
  //   address_2: '',
  //   city: 'Palo Alto',
  //   state: 'CA',
  //   zip: '94304-1104',
  //   country: 'United States',
  // },
  {
    site: 'Prague',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  {
    site: 'Rahway',
    address_1: '126 E. Lincoln Avenue',
    address_2: 'P.O. Box 2000',
    city: 'Rahway',
    state: 'NJ',
    zip: '07065-0900',
    country: 'United States',
  },
  {
    site: 'Singapore',
    address_1: '2 Science Park Drive',
    address_2: '',
    city: 'Singapore',
    state: '',
    zip: '118222',
    country: 'Singapore',
  },
  {
    site: 'South Africa',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  {
    site: 'South San Francisco',
    address_1: '630 Gateway Blvd',
    address_2: '',
    city: 'South San Francisco',
    state: 'CA',
    zip: '94080',
    country: 'United States',
  },
  {
    site: 'Switzerland',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  {
    site: 'Upper Gwynedd',
    address_1: '351 North Sumneytown Pike',
    address_2: 'P.O Box 1000',
    city: 'Upper Gwynedd',
    state: 'PA',
    zip: '19454-2505',
    country: 'United States',
  },
  {
    site: 'West Point',
    address_1: '770 Sumneytown Pike',
    address_2: 'P.O. Box 4',
    city: 'West Point',
    state: 'PA',
    zip: '19486-0004',
    country: 'United States',
  },
  // removed per Amy Aslamkhan
  // {
  //   site: 'Whitehouse Station West',
  //   address_1: 'Two Merck Drive',
  //   address_2: '',
  //   city: 'Whitehouse Station',
  //   state: 'NJ',
  //   zip: '08889-0100',
  //   country: 'United States',
  // },
  {
    site: 'Wilson',
    address_1: '4633 Merck Road',
    address_2: '',
    city: 'Wilson',
    state: 'NC',
    zip: '27893',
    country: 'United States',
  },
  {
    site: 'Other',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
];

export const merckDepartmentList = [
  'Animal Health',
  'Compliance',
  'Finance',
  'Global Human Health',
  'Global Supplier Management Group',
  'Global Workplace & Enterprise Services',
  'Human Resources',
  'Information Technology',
  'Merck Manufacturing Division',
  'Merck Research Laboratories',
  'Office of General Counsel',
  'Other',
];
