export const companies = [
  { account_name: `Salubrent Pharma Solutions` },
  { account_name: `Eutheria Bioscience` },
  { account_name: `Exemplify Biopharma, Inc., a Symeres Company` },
  { account_name: `Expert Biosystems` },
  { account_name: `FG Clean Wipes` },
  { account_name: `PAK BioSolutions` },
  { account_name: `SCHUNK USA` },
  { account_name: `Dec Group` },
  { account_name: `Applied Healthcare Research Management` },
  { account_name: `Biortus` },
  { account_name: `Franz Ziel` },
  { account_name: `Platforce` },
  { account_name: `Prendio` },
  { account_name: `BioProcure` },
  { account_name: `Loftware` },
  { account_name: `Ariadne Software` },
  { account_name: `Amplexor Life Sciences` },
  { account_name: `Nanoform` },
  { account_name: `Rotzinger Pharma Packaging` },
  { account_name: `CleanSpace` },
  { account_name: `Asgard Cleanroom Solutions` },
  { account_name: `Sterling Pharma Solutions` },
  { account_name: `Serán BioScience` },
  { account_name: `Polpharma API` },
  { account_name: `Dipharma Group` },
  { account_name: `Capcium` },
  { account_name: `Eurofins CDMO` },
  { account_name: `KD Pharma Group` },
  { account_name: `PMC Isochem` },
  { account_name: `PROCOS` },
  { account_name: `Renaissance Lakewood` },
  { account_name: `SWECO` },
  { account_name: `Swiss Parenterals Ltd` },
  { account_name: `TCG Lifesciences` },
  { account_name: `Unither Pharmaceuticals` },
  { account_name: `UQUIFA` },
  { account_name: `Veranova` },
  { account_name: `Visum Pharmaceutical` },
  { account_name: `McFlusion` },
  { account_name: `Ameripharma Labs` },
  { account_name: `Aastrid Life Sciences Pvt Ltd` },
  { account_name: `Impact Analytical` },
  { account_name: `SciSafe Inc.` },
  { account_name: `Polaris Pharmaceuticals Inc.` },
  { account_name: `Vazyme Biotech` },
  { account_name: `Caliber Technologies` },
  { account_name: `Primordium Labs` },
  { account_name: `TERA-print` },
  { account_name: `Q Magnetics` },
  { account_name: `PeruseLab` },
  { account_name: `Particle Metrix GmbH` },
  { account_name: `Magnitude Instruments` },
  { account_name: `IRsweep` },
  { account_name: `Intellegens` },
  { account_name: `iChrom Solutions` },
  { account_name: `Lifecore Biomedical` },
  { account_name: `Lannett Company` },
  { account_name: `Kingchem` },
  { account_name: `Gamry Instruments` },
  { account_name: `Edinburgh Instruments` },
  { account_name: `Curapath` },
  { account_name: `Ambeed` },
  { account_name: `Alfa Chemistry` },
  { account_name: `Acrotein Chembio` },
  { account_name: `Elixir Software` },
  { account_name: `Sword Bio` },
  { account_name: `resistanceBio` },
  { account_name: `EpiVax` },
  { account_name: `BioRepository Resources` },
  { account_name: `BioData Solutions` },
  { account_name: `B2S Life Sciences` },
  { account_name: `Ardena` },
  { account_name: `Experic` },
  { account_name: `Excite Pharma Services` },
  { account_name: `Bloomage Biotechnology` },
  { account_name: `Biopeptek Pharmaceuticals` },
  { account_name: `Biddle Sawyer` },
  { account_name: `Axplora` },
  { account_name: `Avara Pharmaceutical Services` },
  { account_name: `VARTEQ` },
  { account_name: `Fivetran` },
  { account_name: `Abzu` },
  { account_name: `ST Pharm` },
  { account_name: `o2h group` },
  { account_name: `CHROMacademy` },
  { account_name: `Verdel Instruments` },
  { account_name: `TOFWERK` },
  { account_name: `Spectroswiss` },
  { account_name: `Spectro Inlets` },
  { account_name: `Silantes` },
  { account_name: `Sierra Analytics` },
  { account_name: `SCAT Americas` },
  { account_name: `QUADROCORE` },
  { account_name: `ProtiFi` },
  { account_name: `MRM Proteomics` },
  { account_name: `MetaSci` },
  { account_name: `Matrix Science` },
  { account_name: `MassTech Inc.` },
  { account_name: `Mass Dynamics` },
  { account_name: `LNI Swissgas` },
  { account_name: `Leybold` },
  { account_name: `Lead Molecular Design` },
  { account_name: `IROA Technologies` },
  { account_name: `IONICON` },
  { account_name: `ionBench` },
  { account_name: `IonOpticks` },
  { account_name: `Yamazen Science` },
  { account_name: `TC Scientific` },
  { account_name: `Flamma Group` },
  { account_name: `SpiroChem` },
  { account_name: `NexMR` },
  { account_name: `Biosensing Instrument` },
  { account_name: `IRBM` },
  { account_name: `Mcule` },
  { account_name: `Aurigene Pharmaceutical Services` },
  { account_name: `PharmaBlock Group` },
  { account_name: `Immunocure Discovery Solutions` },
  { account_name: `Presude Lifesciences` },
  { account_name: `HitGen` },
  { account_name: `Alichem` },
  { account_name: `LAXAI Life Sciences` },
  { account_name: `Aralez Bio` },
  { account_name: `Domainex` },
  { account_name: `GenNext Technologies` },
  { account_name: `Fasmatech Science and Technology` },
  { account_name: `ePrep` },
  { account_name: `Entech Instruments` },
  { account_name: `e-MSion` },
  { account_name: `Ebara Technologies` },
  { account_name: `CoAnn Technologies` },
  { account_name: `CMP Scientific Corp` },
  { account_name: `Bright Giant` },
  { account_name: `BaySpec` },
  { account_name: `Ardara Technologies` },
  { account_name: `AmberGen` },
  { account_name: `Allumiqs` },
  { account_name: `AffiPro s.r.o` },
  { account_name: `Source BioScience` },
  { account_name: `Cambridge Bioscience` },
  { account_name: `Scailyte` },
  { account_name: `Fluent BioSciences` },
  { account_name: `KromaTiD` },
  { account_name: `LevitasBio` },
  { account_name: `RAN Biotechnologies` },
  { account_name: `Vivlion` },
  { account_name: `REALM IDx` },
  { account_name: `ProRelix Research` },
  { account_name: `PathomIQ` },
  { account_name: `Opus Regulatory` },
  { account_name: `Imaging Endpoints` },
  { account_name: `BEKHealth` },
  { account_name: `Aptitude Health` },
  { account_name: `TRIO - Translational Research in Oncology` },
  { account_name: `Trial Library` },
  { account_name: `Tigermed` },
  { account_name: `The Collaboratory` },
  { account_name: `TFS HealthScience` },
  { account_name: `Syapse` },
  { account_name: `NanoMosaic` },
  { account_name: `ERGOMED` },
  { account_name: `Cellworks` },
  { account_name: `Catalyst Clinical Research` },
  { account_name: `Allucent` },
  { account_name: `XenTech` },
  { account_name: `vivoPharm` },
  { account_name: `VIEWORKS` },
  { account_name: `US Biolab Corporation` },
  { account_name: `TribioScience` },
  { account_name: `TheWell Bioscience` },
  { account_name: `Theradex Oncology` },
  { account_name: `Teiko.bio` },
  { account_name: `Targeted Bioscience` },
  { account_name: `SYNCELL` },
  { account_name: `Syd Labs` },
  { account_name: `SpectronRx` },
  { account_name: `Sony Biotechnology` },
  { account_name: `Somark Innovations` },
  { account_name: `SoftMouse.NET` },
  { account_name: `SMOBiO Technology` },
  { account_name: `Sirona Dx` },
  { account_name: `Simport Scientifique` },
  { account_name: `Shuimu BioSciences` },
  { account_name: `Scintica` },
  { account_name: `SciLeads` },
  { account_name: `S2 Genomics` },
  { account_name: `RockStep Solutions` },
  { account_name: `Reddot Biotech` },
  { account_name: `RapID Lab` },
  { account_name: `Quantum-Si` },
  { account_name: `Psomagen` },
  { account_name: `PriMed Shines Bio-tech` },
  { account_name: `PredxBio` },
  { account_name: `Predicine` },
  { account_name: `Factorial Biotechnologies` },
  { account_name: `Slope` },
  { account_name: `Alcyomics` },
  { account_name: `Capstan Therapeutics` },
  { account_name: `V&W Biotech` },
  { account_name: `WHK BioSystems` },
  { account_name: `Hidex` },
  { account_name: `STA Pharmaceutical` },
  { account_name: `GTP Bioways` },
  { account_name: `GL Biochem` },
  { account_name: `Fida Biosystems` },
  { account_name: `EUROAPI` },
  { account_name: `Bioneer A/S` },
  { account_name: `Bionamic` },
  { account_name: `Eurogentec` },
  { account_name: `Provepharm` },
  { account_name: `Precision X-Ray` },
  { account_name: `Precision Biosystems` },
  { account_name: `PreciGenome` },
  { account_name: `Polycarbin` },
  { account_name: `PhenomeX` },
  { account_name: `PharmaLegacy Laboratories` },
  { account_name: `PEPperPRINT GmbH` },
  { account_name: `Pear Bio` },
  { account_name: `Parhelia Biosciences` },
  { account_name: `Paraza Pharma` },
  { account_name: `Panome Bio` },
  { account_name: `Organoid Sciences` },
  { account_name: `Oncolines` },
  { account_name: `OmicsVeu` },
  { account_name: `nRichDX` },
  { account_name: `Novadiscovery` },
  { account_name: `Nona Biosciences` },
  { account_name: `Noble Life Sciences` },
  { account_name: `Nilogen Oncosystems` },
  { account_name: `NeoSome Life Sciences` },
  { account_name: `Nanolive` },
  { account_name: `Mispro` },
  { account_name: `MiRXES` },
  { account_name: `Minerva Biolabs` },
  { account_name: `Metware Biotechnology` },
  { account_name: `Medicilon-MPI Preclinical Research` },
  { account_name: `MEDGENOME` },
  { account_name: `MCRA` },
  { account_name: `M2GEN` },
  { account_name: `LifeOmic` },
  { account_name: `LGC Clinical Diagnostics` },
  { account_name: `Lanterne Dx` },
  { account_name: `KUBTEC Scientific` },
  { account_name: `Krishgen Biosystems` },
  { account_name: `Kibur Medical` },
  { account_name: `Izon Science` },
  { account_name: `IVIM Technology` },
  { account_name: `Invitae` },
  { account_name: `intoDNA` },
  { account_name: `Inteliquet, an IQVIA business` },
  { account_name: `LinusBio` },
  { account_name: `INOVOTION` },
  { account_name: `INNOPSYS` },
  { account_name: `ImaginAb` },
  { account_name: `Ignyte Bio` },
  { account_name: `ICE Bioscience` },
  { account_name: `HypOxygen` },
  { account_name: `HUABIO` },
  { account_name: `Hera BioLabs` },
  { account_name: `Haier Biomedical UK` },
  { account_name: `Gold Biotechnology` },
  { account_name: `Fulgent Genetics` },
  { account_name: `FuGENE` },
  { account_name: `FroggaBio` },
  { account_name: `Fraunhofer IIS` },
  { account_name: `Focus Biomolecules` },
  { account_name: `Fidelis Research` },
  { account_name: `Excellos` },
  { account_name: `Eclipsebio` },
  { account_name: `Cytosurge` },
  { account_name: `CytoRecovery` },
  { account_name: `CloudLIMS` },
  { account_name: `ClearLight Biotechnologies` },
  { account_name: `CHELATEC` },
  { account_name: `CelVivo` },
  { account_name: `Cellgentek` },
  { account_name: `CDI Labs` },
  { account_name: `Burning Rock` },
  { account_name: `BreakSight` },
  { account_name: `Boster Biological Technology` },
  { account_name: `BioVolume` },
  { account_name: `BioSkryb Genomics` },
  { account_name: `Bionl.ai` },
  { account_name: `DenovAI` },
  { account_name: `Biological Dynamics` },
  { account_name: `BioFluidica` },
  { account_name: `Bio X Cell` },
  { account_name: `Bio Serv` },
  { account_name: `AUM BioTech` },
  { account_name: `Audubon Bioscience` },
  { account_name: `Assay Genie` },
  { account_name: `Applied BioPhysics` },
  { account_name: `APExBIO Technology` },
  { account_name: `Adgyl Lifesciences` },
  { account_name: `AcelaBio` },
  { account_name: `Protai` },
  { account_name: `Ocean Genomics` },
  { account_name: `Unlearn.AI` },
  { account_name: `Equilibar` },
  { account_name: `Duoning` },
  { account_name: `Canty` },
  { account_name: `Avance Biosciences` },
  { account_name: `Bio-Works Technologies` },
  { account_name: `ViruSure` },
  { account_name: `Trelleborg Group` },
  { account_name: `Tofflon Group` },
  { account_name: `SmartLabs` },
  { account_name: `Raumedic` },
  { account_name: `Prestige Biologics` },
  { account_name: `Pharma Targeting` },
  { account_name: `PAIA Biotech` },
  { account_name: `Matica Biotechnology` },
  { account_name: `ADS BIOTEC` },
  { account_name: `OmicScouts` },
  { account_name: `Efficient Robotics` },
  { account_name: `Amide Technologies` },
  { account_name: `Delpharm` },
  { account_name: `LumaCyte` },
  { account_name: `Viroclinics` },
  { account_name: `LTS Lohmann Therapie-Systeme AG` },
  { account_name: `Avema Pharma Solutions` },
  { account_name: `Ascendia` },
  { account_name: `Afton Scientific` },
  { account_name: `Tjoapack` },
  { account_name: `NJ Labs` },
  { account_name: `Groupe PARIMA` },
  { account_name: `Vici Health Sciences` },
  { account_name: `Selkirk Pharma` },
  { account_name: `CMIC Group` },
  { account_name: `Woodstock Sterile Solutions` },
  { account_name: `Atrandi Biosciences` },
  { account_name: `Virtusa` },
  { account_name: `Seqera Labs` },
  { account_name: `WEKA` },
  { account_name: `Vivpro Corp` },
  { account_name: `Via Scientific` },
  { account_name: `Validated Cloud` },
  { account_name: `Scality` },
  { account_name: `RCH Solutions` },
  { account_name: `Qumulo` },
  { account_name: `Quantori` },
  { account_name: `QC Ware Corp.` },
  { account_name: `Monomer Bio` },
  { account_name: `metaphacts GmbH` },
  { account_name: `MedChemica` },
  { account_name: `Kanda Software` },
  { account_name: `Glencoe Software` },
  { account_name: `Fortinet` },
  { account_name: `Datagrok` },
  { account_name: `Captario` },
  { account_name: `Boulder BioConsulting` },
  { account_name: `Biomage` },
  { account_name: `Arrayo` },
  { account_name: `Rescale` },
  { account_name: `Rancho BioSciences` },
  { account_name: `Quilt Data` },
  { account_name: `Ontotext` },
  { account_name: `MResult` },
  { account_name: `Flywheel.io` },
  { account_name: `DrugBank` },
  { account_name: `Discngine` },
  { account_name: `DataCicada` },
  { account_name: `Code Poets` },
  { account_name: `Clovertex` },
  { account_name: `Centaur Labs` },
  { account_name: `Capish` },
  { account_name: `Birlasoft` },
  { account_name: `BioRender` },
  { account_name: `BigOmics Analytics` },
  { account_name: `Arkatechture` },
  { account_name: `Sciencix` },
  { account_name: `NACALAI TESQUE` },
  { account_name: `Dr. Maisch HPLC` },
  { account_name: `Antec Scientific` },
  { account_name: `Biomol` },
  { account_name: `b.safe a brand of BOHLENDER` },
  { account_name: `Azbil Telstar` },
  { account_name: `Steri-Tek` },
  { account_name: `Spire Integrated Solutions` },
  { account_name: `Setra Systems` },
  { account_name: `PSC Biotech Corporation` },
  { account_name: `PQE Group` },
  { account_name: `Bahnson Environmental Specialties` },
  { account_name: `LePure Biotech` },
  { account_name: `INTERSCIENCE Lab` },
  { account_name: `Comecer` },
  { account_name: `AquiSense Technologies` },
  { account_name: `Zaiput Flow Technologies` },
  { account_name: `Kitted Lab Technologies` },
  { account_name: `AustinPx` },
  { account_name: `August Bioservices` },
  { account_name: `Martel Instruments` },
  { account_name: `Inorganic Ventures` },
  { account_name: `GFS Chemicals` },
  { account_name: `Axcend` },
  { account_name: `ZAGENO` },
  { account_name: `Labviva` },
  { account_name: `Diatheva` },
  { account_name: `RD-BIOTECH` },
  { account_name: `FairJourney Biologics` },
  { account_name: `Sanyou Biopharmaceuticals` },
  { account_name: `Constant Systems` },
  { account_name: `Integral Molecular` },
  { account_name: `STC Biologics Inc` },
  { account_name: `MOLCURE` },
  { account_name: `Affinité Instruments` },
  { account_name: `Helix Biostructures` },
  { account_name: `GENOSKIN` },
  { account_name: `Dima Biotechnology` },
  { account_name: `ChemPartner` },
  { account_name: `Neochromosome` },
  { account_name: `StackWave` },
  { account_name: `immunAware` },
  { account_name: `Antibody Analytics` },
  { account_name: `LS Instruments` },
  { account_name: `MiLaboratories` },
  { account_name: `Yurogen Biosystems` },
  { account_name: `KYINNO Biotechnology` },
  { account_name: `Porvair Sciences` },
  { account_name: `L&M Data Science` },
  { account_name: `Caltag Medsystems` },
  { account_name: `GC biotech` },
  { account_name: `Stratech Scientific` },
  { account_name: `Synthego` },
  { account_name: `Medicines Discovery Catapult` },
  { account_name: `Iterative` },
  { account_name: `Explicyte` },
  { account_name: `STARLIMS` },
  { account_name: `RIC biologics` },
  { account_name: `AnaBioTec` },
  { account_name: `FOx Biosystems` },
  { account_name: `Rockland Immunochemicals` },
  { account_name: `KYNOTA` },
  { account_name: `Prolytix` },
  { account_name: `Zaber Technologies` },
  { account_name: `Yahara Software` },
  { account_name: `NEST Scientific USA` },
  { account_name: `Montana Molecular` },
  { account_name: `MolGen` },
  { account_name: `DPX Technologies` },
  { account_name: `diaago` },
  { account_name: `Dexter Magnetic Technologies` },
  { account_name: `Wainamics` },
  { account_name: `UPM Biomedicals` },
  { account_name: `TriContinent` },
  { account_name: `Trailhead Biosystems` },
  { account_name: `Thrive Bioscience` },
  { account_name: `SYNENTEC GmbH` },
  { account_name: `SUN bioscience` },
  { account_name: `Sound Analytics` },
  { account_name: `SilcoTek Corporation` },
  { account_name: `Sigsense Technologies` },
  { account_name: `MK Fluidic Systems` },
  { account_name: `MISUMI` },
  { account_name: `Minitubes` },
  { account_name: `Meslo Americas` },
  { account_name: `Meniscense` },
  { account_name: `MedChemExpress` },
  { account_name: `Labforward` },
  { account_name: `Key Technology` },
  { account_name: `JSat Automation` },
  { account_name: `Irish Life Sciences` },
  { account_name: `Curiosis` },
  { account_name: `Copia Scientific` },
  { account_name: `Contained Air Solutions` },
  { account_name: `Confluence Discovery Technologies` },
  { account_name: `Cognex Corporation` },
  { account_name: `Cellular Highways` },
  { account_name: `CellPort Software` },
  { account_name: `Cellbox Solutions` },
  { account_name: `BioSignal2` },
  { account_name: `Bio Molecular Systems` },
  { account_name: `Sandpiper Technical Solutions` },
  { account_name: `Quartzy` },
  { account_name: `QINSTRUMENTS` },
  { account_name: `PCR Biosystems` },
  { account_name: `Particle Works` },
  { account_name: `OMS Motion` },
  { account_name: `Olympus Controls` },
  { account_name: `Nugget Scientific` },
  { account_name: `HayMan Automation` },
  { account_name: `GeSiM Gesellschaft fuer Silizium-Mikrosysteme mbH` },
  { account_name: `Excel Scientific` },
  { account_name: `EQUIcon Software` },
  { account_name: `eLabNext` },
  { account_name: `Bartels Mikrotechnik` },
  { account_name: `Axcelead Drug Discovery Partners` },
  { account_name: `Aspen Biosciences` },
  { account_name: `Arvensis` },
  { account_name: `Artificial` },
  { account_name: `Ardigen` },
  { account_name: `apartis Information Management` },
  { account_name: `Analytical Sales and Services, Inc` },
  { account_name: `AltemisLab` },
  { account_name: `AFYS3G` },
  { account_name: `Advanced Microfluidics` },
  { account_name: `ABLE Labs` },
  { account_name: `XiFin` },
  { account_name: `Virasoft` },
  { account_name: `Teledyne Lumenera` },
  { account_name: `Techcyte` },
  { account_name: `Sectra` },
  { account_name: `Scopio Labs` },
  { account_name: `RWD Life Science` },
  { account_name: `Prime Life Sciences` },
  { account_name: `PreciPoint` },
  { account_name: `Pramana` },
  { account_name: `OptraSCAN` },
  { account_name: `Novodiax` },
  { account_name: `MS Validated Antibodies` },
  { account_name: `Motic Digital Pathology` },
  { account_name: `Morphle Labs` },
  { account_name: `Molecular Instruments` },
  { account_name: `Mindpeak` },
  { account_name: `Mikroscan Technologies` },
  { account_name: `KFBIO` },
  { account_name: `ISENET-USA` },
  { account_name: `iProcess Global Research` },
  { account_name: `INFINITT North America` },
  { account_name: `Ibex Medical Analytics` },
  { account_name: `Huron Digital Pathology` },
  { account_name: `Grundium` },
  { account_name: `FormaPath` },
  { account_name: `Dolbey` },
  { account_name: `DigitCells` },
  { account_name: `DeePathology.ai` },
  { account_name: `Dakewe Medical` },
  { account_name: `Corista` },
  { account_name: `Change Healthcare` },
  { account_name: `Cancer Diagnostics` },
  { account_name: `Bradley Products` },
  { account_name: `Bioworkshops` },
  { account_name: `Aktana` },
  { account_name: `Rapid Novor` },
  { account_name: `PipeBio` },
  { account_name: `NUMAFERM` },
  { account_name: `InstaDeep Ltd` },
  { account_name: `HexagonFab` },
  { account_name: `Steriline` },
  { account_name: `Sensum` },
  { account_name: `Ecolab` },
  { account_name: `Belimed Life Science` },
  { account_name: `Boston Cell Standards` },
  { account_name: `Biomiga` },
  { account_name: `Biofidelity` },
  { account_name: `Bio SB` },
  { account_name: `Anchor Molecular` },
  { account_name: `AIxMed` },
  { account_name: `AIRA Matrix Private Limited` },
  { account_name: `TriMetis Life Sciences` },
  { account_name: `Dr. Reddy's Active Pharmaceutical Ingredients` },
  { account_name: `KineMed` },
  { account_name: `Virscio` },
  { account_name: `Immunologix Laboratories` },
  { account_name: `AmplifyBio` },
  { account_name: `Axle Informatics` },
  { account_name: `Anima Biotech` },
  { account_name: `Incisive Genetics` },
  { account_name: `Mana.bio` },
  { account_name: `Polymun Scientific Immunbiologische Forschung GmbH` },
  { account_name: `leon-nanodrugs` },
  { account_name: `NanoTech Pharma` },
  { account_name: `Aust Business Solutions` },
  { account_name: `Helix Biotech` },
  { account_name: `Bionomous` },
  { account_name: `Exelead` },
  { account_name: `Elephas` },
  { account_name: `BioXcel` },
  { account_name: `Transition Bio` },
  { account_name: `Brightseed` },
  { account_name: `Olaris` },
  { account_name: `Replay` },
  { account_name: `Alltrna` },
  { account_name: `Genpro Research` },
  { account_name: `PROMETRIKA` },
  { account_name: `Algorics` },
  { account_name: `K3-Innovations` },
  { account_name: `Harbor Clinical` },
  { account_name: `Quintara Discovery (QDI)` },
  { account_name: `Axcellerate Pharma` },
  { account_name: `Ablexis` },
  { account_name: `PepTech` },
  { account_name: `Agno Pharma` },
  { account_name: `SynChem` },
  { account_name: `CritiTech Particle Engineering Solutions` },
  { account_name: `Discovery Clinical Trials` },
  { account_name: `Ledger Run` },
  { account_name: `Levena Biopharma` },
  { account_name: `Essence medchem Corporation` },
  { account_name: `i3 Innovus` },
  { account_name: `Wilmington PharmaTech` },
  { account_name: `Beta Pharma` },
  { account_name: `Sever Pharma Solutions (formerly Foster Delivery Science)` },
  { account_name: `PSNResearch` },
  { account_name: `Hill Top Research` },
  { account_name: `Oso BioPharmaceuticals Manufacturing` },
  { account_name: `AXIS Clinicals USA` },
  { account_name: `Purisys` },
  { account_name: `Pegasus Laboratories` },
  { account_name: `InClin` },
  { account_name: `Impact Pharmaceutical Services` },
  { account_name: `Alliance for Multispecialty Research` },
  { account_name: `LGM Pharma` },
  { account_name: `Policy Analysis` },
  { account_name: `Santé Laboratories` },
  { account_name: `Lovelace Biomedical` },
  { account_name: `Clinimetrics` },
  { account_name: `Technical Resources International` },
  { account_name: `XenoTech` },
  { account_name: `FDAQRC` },
  { account_name: `Particle Sciences, A Lubrizol Life Science Company` },
  { account_name: `Sciformix` },
  { account_name: `Catawba Research` },
  { account_name: `ClinEssentials` },
  { account_name: `CBCC Global Research` },
  { account_name: `Zenith LifeScience` },
  { account_name: `Katalyst HealthCares & Life Sciences` },
  { account_name: `Linical Americas` },
  { account_name: `Tergus Pharma` },
  { account_name: `MLM Medical Labs` },
  { account_name: `Lipotype` },
  { account_name: `BluTest Laboratories — A Tentamus Company` },
  { account_name: `Moredun Scientific` },
  { account_name: `The Native Antigen Company` },
  { account_name: `Imagen Therapeutics` },
  { account_name: `IBT Bioservices` },
  { account_name: `Surgenex` },
  { account_name: `Imanis Life Sciences` },
  { account_name: `Quantimetrix` },
  { account_name: `Genovac` },
  { account_name: `Avioq` },
  { account_name: `Ichor Life Sciences` },
  { account_name: `StemExpress` },
  {
    account_name: `ESTERN Medical (CRO) Clinical Contract Research Organization`,
  },
  { account_name: `InfinixBio` },
  { account_name: `myNEO` },
  { account_name: `BioLizard` },
  { account_name: `Agenda Life Sciences` },
  { account_name: `Lunaphore` },
  { account_name: `Aspect Analytics` },
  { account_name: `Navinci Diagnostics` },
  { account_name: `Enable Medicine` },
  { account_name: `BioSpring GmbH` },
  { account_name: `Axolabs GmbH` },
  { account_name: `UFAG Laboratorien AG` },
  { account_name: `CSBio` },
  { account_name: `Ypso-Facto` },
  { account_name: `Boston Micro Fabrication - BMF` },
  { account_name: `Curi Bio` },
  { account_name: `Aignostics` },
  { account_name: `Exsera BioLabs` },
  { account_name: `SNBL` },
  { account_name: `AHN Biotechnologie` },
  { account_name: `Apera Instruments` },
  { account_name: `Syft Technologies` },
  { account_name: `Mycenax Biotech` },
  { account_name: `Azbil` },
  { account_name: `RADOM Corporation` },
  { account_name: `JAF Consulting` },
  { account_name: `ApiJect` },
  { account_name: `3DSecret` },
  { account_name: `Prognos Health` },
  { account_name: `Manifold` },
  { account_name: `StageBio` },
  { account_name: `TandemAI` },
  { account_name: `RECEPTOR.AI` },
  { account_name: `Omnia Biosystems` },
  { account_name: `Canfield Scientific` },
  { account_name: `Kantar Health` },
  { account_name: `TriRx Pharmaceutical Services` },
  { account_name: `Rottendorf Pharma` },
  { account_name: `Megazyme` },
  { account_name: `Alera Labs` },
  { account_name: `Exum Instruments` },
  { account_name: `George Clinical` },
  { account_name: `Medrio` },
  { account_name: `ClinChoice` },
  { account_name: `Concentrics Research, an IQVIA business` },
  { account_name: `Bridge Laboratories` },
  { account_name: `DP Clinical` },
  { account_name: `IPA (ImmunoPrecise Antibodies)` },
  { account_name: `Quantificare` },
  { account_name: `Proxima Clinical Research` },
  { account_name: `PharPoint Research` },
  { account_name: `Cromos Pharma` },
  { account_name: `OncoBay Clinical` },
  { account_name: `NJ Bio` },
  { account_name: `Vial` },
  { account_name: `NoyMed CRO` },
  { account_name: `Beaufort CRO` },
  { account_name: `MERIT CRO` },
  { account_name: `EastHORN - A Novotech Company` },
  { account_name: `hVIVO` },
  { account_name: `Accutest Research Laboratories` },
  { account_name: `PHASTAR` },
  { account_name: `MAC Clinical Research` },
  { account_name: `OPIS s.r.l.` },
  { account_name: `Emmes` },
  { account_name: `Symbiance` },
  { account_name: `Synteract` },
  { account_name: `Advanced Clinical` },
  { account_name: `Accelsiors CRO` },
  { account_name: `Momentum Biotechnologies` },
  { account_name: `Aminoverse` },
  { account_name: `Veloxity Labs` },
  { account_name: `Certis Oncology Solutions` },
  { account_name: `ProSciento` },
  { account_name: `Natera` },
  { account_name: `Target Health | Full-Service CRO` },
  { account_name: `Applied DNA Sciences` },
  { account_name: `TIB Molbiol Syntheselabor GmbH` },
  { account_name: `Omnicare, a CVS Health company` },
  { account_name: `Systems Imagination` },
  { account_name: `Sussex Research Laboratories` },
  { account_name: `TOPAS® COC by Polyplastics` },
  { account_name: `Sophic Alliance` },
  { account_name: `SciPro / Scientific Products & Equipment` },
  { account_name: `Rosa &` },
  { account_name: `Retsch - Milling and Sieving` },
  { account_name: `rPeptide` },
  { account_name: `SGS Quay Pharma` },
  { account_name: `Quantabio` },
  { account_name: `Quanta BioDesign, Ltd.` },
  { account_name: `PTI - Packaging Technologies & Inspection` },
  { account_name: `On-Line Instrument Systems` },
  { account_name: `Neoclone Biotechnology` },
  { account_name: `NeoBiosystems` },
  { account_name: `Myonex` },
  { account_name: `Micro Measurement Labs` },
  { account_name: `Microprobes for Life Science` },
  { account_name: `METO Systems` },
  { account_name: `MedKoo Biosciences` },
  { account_name: `Mech-Chem Associates` },
  { account_name: `M.A.R. S.R.L.` },
  { account_name: `Lark Health` },
  { account_name: `LKT Laboratories` },
  { account_name: `LJ Star` },
  { account_name: `Texwipe` },
  { account_name: `IPS Therapeutique (IPST)` },
  { account_name: `Igenbio` },
  { account_name: `InnovoCommerce` },
  { account_name: `ICL` },
  { account_name: `Immuno-Biological Laboratories` },
  { account_name: `HS Design` },
  { account_name: `Verista` },
  { account_name: `H&A Scientific` },
  { account_name: `Future Pak` },
  { account_name: `Geneuity Clinical Research Services` },
  { account_name: `exonbio` },
  { account_name: `Eidogen-Sertanty` },
  { account_name: `Digital Analysis Corp.` },
  { account_name: `DFnet` },
  { account_name: `Cmed Clinical Services` },
  { account_name: `Mauna Kea Technologies` },
  { account_name: `CliniSciences Group` },
  { account_name: `Biopharma Group` },
  { account_name: `Geneious` },
  { account_name: `Explora BioLabs` },
  { account_name: `Bio-Technical Resources` },
  { account_name: `Translational Drug Development (TD2)` },
  { account_name: `Raj Analytical Solutions Pvt Ltd` },
  { account_name: `Praxis Packaging Solutions` },
  { account_name: `Ocean NanoTech` },
  { account_name: `Nissan Chemical America Corporation` },
  { account_name: `NJM Packaging` },
  { account_name: `NDA Group AB` },
  { account_name: `MMI - Molecular Machines & Industries` },
  { account_name: `Manz AG` },
  { account_name: `KIN-TEK Analytical` },
  { account_name: `Dycem Contamination Control` },
  { account_name: `Novocol Pharma` },
  { account_name: `DNA Software` },
  { account_name: `Certus International` },
  { account_name: `BIOBUBBLE` },
  { account_name: `Banner Life Sciences` },
  { account_name: `Comera Life Sciences` },
  { account_name: `Arranta Bio` },
  { account_name: `VERAXA Biotech` },
  { account_name: `RotaChrom Technologies` },
  { account_name: `Cell and Gene Therapy Catapult` },
  { account_name: `Bionet` },
  { account_name: `BioAtrium AG` },
  { account_name: `4basebio` },
  { account_name: `3D-PharmXchange` },
  { account_name: `Dark Horse Consulting Group` },
  { account_name: `Bionter` },
  { account_name: `VIVEbiotech` },
  { account_name: `DiNAQOR` },
  { account_name: `Amniotics AB` },
  { account_name: `MeiraGTx` },
  { account_name: `ElevateBio` },
  { account_name: `RoweMed AG - Medical 4 Life` },
  { account_name: `DFE Pharma` },
  { account_name: `Causeway Sensors Limited` },
  { account_name: `Aptamer Group` },
  { account_name: `Optimal Industrial Technologies` },
  { account_name: `SMARTFREEZ - Precision Cryosystems` },
  { account_name: `Enzene Biosciences` },
  { account_name: `Bilfinger Life Science` },
  { account_name: `4TE` },
  { account_name: `Protein BioSolutions` },
  { account_name: `iGenomX` },
  { account_name: `Jumpcode Genomics` },
  { account_name: `Cline Scientific` },
  { account_name: `Fluicell AB` },
  { account_name: `Rebus Biosystems` },
  { account_name: `Statsols | nQuery` },
  { account_name: `Nanologica AB` },
  { account_name: `Nanexa` },
  { account_name: `Intract Pharma` },
  { account_name: `Nanomerics Ltd` },
  { account_name: `RSSL` },
  { account_name: `Navigate BioPharma Services` },
  { account_name: `Nordic Bioscience A/S` },
  { account_name: `Minerva Imaging` },
  { account_name: `Aptys Pharma` },
  { account_name: `Anogen-Yes Biotech Laboratories LTD.` },
  { account_name: `Miles Scientific` },
  { account_name: `Molnár-Institute for Applied Chromatography` },
  { account_name: `GJ Chemical` },
  { account_name: `INOSIM Group` },
  { account_name: `HTI Plastics` },
  { account_name: `INHECO Industrial Heating & Cooling GmbH` },
  { account_name: `Societal™ CDMO` },
  { account_name: `J-STAR Research` },
  { account_name: `Labcon` },
  { account_name: `LabX Media Group` },
  { account_name: `LSBio | LifeSpan BioSciences` },
  { account_name: `M&O Perry Industries` },
  { account_name: `MIDSCI` },
  { account_name: `MyMeds&Me` },
  { account_name: `NETZSCH Analyzing & Testing` },
  { account_name: `Novum Pharmaceutical Research Services` },
  { account_name: `Oakwood Labs` },
  { account_name: `OGT` },
  { account_name: `PAC - Petroleum Analyzer Company` },
  { account_name: `PMRS` },
  { account_name: `Bitbrain` },
  { account_name: `DrugPatentWatch` },
  { account_name: `PSI CRO` },
  { account_name: `QTest Labs` },
  { account_name: `RD Laboratories` },
  { account_name: `reMYND` },
  { account_name: `Ripple Neuro` },
  { account_name: `TekniPlex` },
  { account_name: `Thomas Processing` },
  { account_name: `Volt` },
  { account_name: `WorldCare Clinical` },
  { account_name: `XOMA` },
  { account_name: `Girton Manufacturing` },
  { account_name: `G-Biosciences` },
  { account_name: `Fristam Pumps USA` },
  { account_name: `FOSS` },
  { account_name: `Eurofins | EAG Laboratories` },
  { account_name: `Enerquip Thermal Solutions` },
  { account_name: `Eagle Stainless Tube & Fabrication` },
  { account_name: `Fischione Instruments` },
  { account_name: `SPOT Imaging` },
  { account_name: `B&P Littleford` },
  { account_name: `Cadmach Machinery` },
  { account_name: `CCL Clinical Systems` },
  { account_name: `Clinigen Clinical Supplies Management` },
  { account_name: `Cerbios-Pharma SA` },
  { account_name: `Caligor Coghlan Pharma Services` },
  { account_name: `CDN isotopes` },
  { account_name: `C.W. Brabender Instruments` },
  { account_name: `BUCHI Laboratory Equipment` },
  { account_name: `Boekel Scientific` },
  { account_name: `Bioquell` },
  { account_name: `BRANY` },
  { account_name: `BIOLYPH` },
  { account_name: `BIO-CAT Enzymes & Microbials` },
  { account_name: `BioCheck` },
  { account_name: `BBK Worldwide` },
  { account_name: `AZO` },
  { account_name: `Astech Projects - Robotics & Automation` },
  { account_name: `Amarex Clinical Research` },
  { account_name: `ATL Printing and Converting` },
  { account_name: `TECHLITE® Acoustics & Insulation` },
  { account_name: `Accel Research Sites` },
  { account_name: `Rubber Fab` },
  { account_name: `Sakura Finetek USA` },
  { account_name: `SGD Pharma` },
  { account_name: `Sharp Services` },
  { account_name: `SiO2 Materials Science` },
  { account_name: `SMC` },
  { account_name: `SCC Soft Computer` },
  { account_name: `Statgraphics Technologies` },
  { account_name: `iotaSciences` },
  { account_name: `Roncadelle Operations` },
  { account_name: `emp Biotech` },
  { account_name: `Myllia Biotechnology` },
  { account_name: `ATS Life Sciences Scientific Products` },
  { account_name: `Ziath` },
  { account_name: `TrakCel` },
  { account_name: `SCTbio` },
  { account_name: `QuickSTAT Global Life Science Logistics` },
  { account_name: `Peli BioThermal` },
  { account_name: `Immudex` },
  { account_name: `Grifols` },
  { account_name: `Exothera` },
  { account_name: `Alloy Therapeutics` },
  { account_name: `Adimab` },
  { account_name: `ScaleReady` },
  { account_name: `Samplix` },
  { account_name: `Aquila Solutions` },
  { account_name: `Anatomic` },
  { account_name: `InSCREENeX` },
  { account_name: `innoVitro` },
  { account_name: `ImmuONE` },
  { account_name: `Genesis Drug Discovery & Development (GD³)` },
  { account_name: `Essent Biologics` },
  { account_name: `DefiniGEN` },
  { account_name: `Datirium` },
  { account_name: `Cytotheryx` },
  { account_name: `Xybion Digital` },
  { account_name: `Toxys` },
  { account_name: `ToxStrategies` },
  { account_name: `ToxMinds BVBA` },
  { account_name: `SynVivo` },
  { account_name: `Shanghai Medicilon` },
  { account_name: `SenzaGen` },
  { account_name: `SCIREQ Scientific Respiratory Equipment` },
  { account_name: `Scantox Group` },
  { account_name: `Revivocell Ltd.` },
  { account_name: `PrecisionTox` },
  { account_name: `NeuCyte` },
  { account_name: `MB Research Laboratories` },
  { account_name: `MaxWell Biosystems` },
  { account_name: `MatTek Life Sciences` },
  { account_name: `LifeNet Health LifeSciences` },
  { account_name: `ViQi` },
  { account_name: `Veon Scientific` },
  { account_name: `LiveDrop` },
  { account_name: `lino Biotech` },
  { account_name: `Limmi` },
  { account_name: `KML Vision` },
  { account_name: `KACTUS` },
  { account_name: `Protein Fluidics` },
  { account_name: `Celltheon Corporation` },
  { account_name: `Elabscience` },
  { account_name: `uFluidix` },
  { account_name: `RefLab ApS` },
  { account_name: `Nextcea` },
  { account_name: `NEURON EXPERTS` },
  { account_name: `Multispan` },
  { account_name: `iXCells Biotechnologies` },
  { account_name: `IQ Proteomics` },
  { account_name: `Excelra` },
  { account_name: `Dalton Bioanalytics` },
  { account_name: `Clinnovate Health` },
  { account_name: `Cellaria` },
  { account_name: `Aracari Biosciences` },
  { account_name: `AnaBios Corporation` },
  { account_name: `ALSTEM` },
  { account_name: `AAA Service Laboratory` },
  { account_name: `LVL technologies GmbH & Co. KG` },
  { account_name: `TDG Scientific` },
  { account_name: `Novotech` },
  { account_name: `Avance Clinical` },
  { account_name: `Axolotl Biosciences` },
  { account_name: `BioMathematica` },
  { account_name: `JANVIER LABS` },
  { account_name: `Jurata Thin Film` },
  { account_name: `Immuto Scientific` },
  { account_name: `Harro Höfliger` },
  { account_name: `EVQLV` },
  { account_name: `Detai Bioscience` },
  { account_name: `CliniOps` },
  { account_name: `Clean Cells` },
  { account_name: `CHO Plus` },
  { account_name: `ArcanaBio` },
  { account_name: `Ananta Technologies` },
  { account_name: `Amador Bioscience` },
  { account_name: `AltruBio` },
  { account_name: `AlphaThera` },
  { account_name: `AlivaMab Discovery Services` },
  { account_name: `Precision Biologics` },
  { account_name: `Ossianix` },
  { account_name: `Mabqi` },
  { account_name: `LUMICKS` },
  { account_name: `L7 Informatics` },
  { account_name: `Icosagen Group` },
  { account_name: `Genialis` },
  { account_name: `FyoniBio` },
  { account_name: `ENPICOM` },
  { account_name: `SciMar ONE` },
  { account_name: `Pepper Bio` },
  { account_name: `IndyGeneUS AI` },
  { account_name: `Magna Labs` },
  { account_name: `Gesund.ai` },
  { account_name: `Deep Forest Sciences` },
  { account_name: `Andson Biotech` },
  { account_name: `Celine Therapeutics` },
  { account_name: `Polygon Health` },
  { account_name: `Perceiv AI` },
  { account_name: `FLUICS` },
  { account_name: `Neurix SA` },
  { account_name: `ThinkCyte` },
  { account_name: `Nodexus` },
  { account_name: `INOREVIA` },
  { account_name: `Dynamic Biosensors` },
  { account_name: `Byonoy` },
  { account_name: `BLINK AG` },
  { account_name: `AMETEK Brookfield` },
  { account_name: `ITR Laboratories Canada` },
  { account_name: `Ellab - Validation & Monitoring Solutions` },
  { account_name: `Oliver Healthcare Packaging` },
  { account_name: `Buehler An ITW Company` },
  { account_name: `MMS` },
  { account_name: `AMETEK MOCON` },
  { account_name: `QPharma` },
  { account_name: `R-V Industries` },
  { account_name: `ATS Life Sciences Bel-Art & Wilmad` },
  { account_name: `C & J Industries` },
  { account_name: `Avra` },
  { account_name: `Fres-co System USA` },
  { account_name: `OPTEL Group` },
  { account_name: `Laboratoires Confab` },
  { account_name: `Pharm-Olam International` },
  { account_name: `Brand` },
  { account_name: `Pii (Pharmaceutics International)` },
  { account_name: `Mareana` },
  { account_name: `Lumencor` },
  { account_name: `Genezen` },
  { account_name: `GBI Bio` },
  { account_name: `Genome International` },
  { account_name: `Geltor` },
  { account_name: `Full Spectrum Group` },
  { account_name: `NorthEast BioLab` },
  { account_name: `Scorpius Biomanufacturing` },
  { account_name: `TBL Performance Plastics - A SaniSure Company` },
  { account_name: `21st Century Biochemicals` },
  { account_name: `Prosensus` },
  { account_name: `Castor Technology Corp.` },
  { account_name: `ZS | Intomics` },
  { account_name: `Accelerated Enrollment Solutions` },
  { account_name: `Norlake` },
  { account_name: `SK Biotek` },
  { account_name: `L.B. Bohle` },
  { account_name: `Trinity Life Sciences` },
  { account_name: `AIXIAL` },
  { account_name: `NI (National Instruments)` },
  { account_name: `ProSys Group` },
  { account_name: `AVS / Advanced Visual Systems` },
  { account_name: `CCDC - The Cambridge Crystallographic Data Centre` },
  { account_name: `Cenmed Enterprises` },
  { account_name: `Glas-Col` },
  { account_name: `Hichrom Limited` },
  { account_name: `Integrated Lab Solutions` },
  { account_name: `PatientsLikeMe` },
  { account_name: `Pharmoptima` },
  { account_name: `Lab-Ally` },
  { account_name: `Zinsser North America` },
  { account_name: `Omicron Scientific` },
  { account_name: `Healthcare Consultancy Group - HCG` },
  { account_name: `Fab'entech` },
  { account_name: `Actlabs (Activation Laboratories Ltd.)` },
  { account_name: `Revolution Biosciences` },
  { account_name: `Tipping Point Media` },
  { account_name: `HTANALYSTS` },
  { account_name: `Market Access Transformation, a Genesis Research company` },
  { account_name: `Accu-Glass` },
  { account_name: `KRÜSS - Advancing your Surface Science` },
  { account_name: `Ingersoll Rand` },
  { account_name: `Pharmascience` },
  { account_name: `ARI - Art Robbins Instruments` },
  { account_name: `Rigaku Analytical Devices` },
  { account_name: `Keen Eye by Tribun Health` },
  { account_name: `Tarsons` },
  { account_name: `STOE & Cie` },
  { account_name: `FIT Additive Manufacturing Group` },
  { account_name: `R-Biopharm Group` },
  { account_name: `Ortoalresa` },
  { account_name: `microfluidic ChipShop` },
  { account_name: `HTL Biotechnology` },
  { account_name: `Gifford Bioscience Limited` },
  { account_name: `Great Place To Work US` },
  { account_name: `Meridian Bioscience` },
  { account_name: `Galilei Software` },
  { account_name: `expert.ai` },
  { account_name: `EOS` },
  { account_name: `Cisa Sieving Technologies` },
  { account_name: `ACG World` },
  { account_name: `Actylis` },
  { account_name: `Progress Chef` },
  { account_name: `BioServUK` },
  { account_name: `ASKION Biobanking` },
  { account_name: `ACIC Pharmaceuticals` },
  { account_name: `Venn Life Sciences - part of hVIVO plc` },
  { account_name: `Oramed Pharmaceuticals` },
  { account_name: `Takeda` },
  { account_name: `Ionis Pharmaceuticals` },
  { account_name: `VGXI` },
  { account_name: `Avitide` },
  { account_name: `Spherotech` },
  { account_name: `Unified Information Devices` },
  { account_name: `ONI` },
  { account_name: `S-BIO - A part of Sumitomo Bakelite Co., Ltd.` },
  { account_name: `arivis - a ZEISS company` },
  { account_name: `Cusabio` },
  { account_name: `Abcore | Fortis Life Sciences` },
  { account_name: `Ambiotis` },
  { account_name: `VelaLabs` },
  { account_name: `Upperton Pharma Solutions` },
  { account_name: `Cellply` },
  { account_name: `INCOG BioPharma Services` },
  { account_name: `Nucro-Technics` },
  { account_name: `JOINN Biologics` },
  { account_name: `Neoteryx` },
  { account_name: `IBA Lifesciences` },
  { account_name: `Research Products International` },
  { account_name: `Decon Labs` },
  { account_name: `Caron Products UK (formerly Bigneat Ltd)` },
  { account_name: `OMNI International` },
  { account_name: `Alpaqua Engineering` },
  { account_name: `The Hackett Group` },
  { account_name: `The Patient Recruiting Agency` },
  { account_name: `The Scale People` },
  { account_name: `Systec` },
  { account_name: `Uhlmann Pac-Systeme` },
  { account_name: `Unichem Laboratories Limited` },
  { account_name: `UBC` },
  { account_name: `ROSALIND` },
  { account_name: `Scale Biosciences` },
  { account_name: `Eventdex` },
  { account_name: `QuartzBio, part of Precision for Medicine` },
  { account_name: `Deep 6 AI` },
  { account_name: `Narrativa` },
  { account_name: `Korio` },
  { account_name: `SubjectWell` },
  { account_name: `Pharma Start` },
  { account_name: `TransCelerate BioPharma` },
  { account_name: `Continuum Clinical` },
  { account_name: `HealthVerity` },
  { account_name: `mdgroup` },
  { account_name: `Pro-ficiency` },
  { account_name: `Viedoc` },
  { account_name: `CorEvitas` },
  { account_name: `Eko` },
  { account_name: `xCures` },
  { account_name: `IMA Clinical Research` },
  { account_name: `DT Consulting` },
  { account_name: `WCG` },
  { account_name: `ProofPilot` },
  { account_name: `Advarra` },
  { account_name: `Conversis` },
  { account_name: `EmVenio Research` },
  { account_name: `Circuit Clinical` },
  { account_name: `VivoSense` },
  { account_name: `Open Targets` },
  { account_name: `GATC Health` },
  { account_name: `Cantata Bio` },
  { account_name: `BioXpedia A/S - Bioanalytical Service` },
  { account_name: `Acuity Spatial Genomics` },
  { account_name: `CENTOGENE` },
  { account_name: `Zef Scientific` },
  { account_name: `Veranex` },
  { account_name: `Spex` },
  { account_name: `Excelya` },
  { account_name: `newLab` },
  { account_name: `DistillerSR` },
  { account_name: `Lifescience Dynamics Limited` },
  { account_name: `AVM Biomed` },
  { account_name: `Calibre Scientific` },
  { account_name: `ABX-CRO` },
  { account_name: `Accu-Seal SencorpWhite` },
  { account_name: `Aesus Packaging Systems` },
  { account_name: `All-Fill` },
  { account_name: `Animated Dynamics` },
  { account_name: `Chai` },
  { account_name: `CHMLAB GROUP` },
  { account_name: `Editage` },
  { account_name: `Esco` },
  { account_name: `Galen Laboratory Supplies` },
  { account_name: `LABOMATIC Instruments AG` },
  { account_name: `Martin Christ Freeze Dryers.` },
  { account_name: `NCPC International Corp.` },
  { account_name: `Omega Design Corporation` },
  { account_name: `Redd & Whyte Limited` },
  { account_name: `PRECISIONheor` },
  { account_name: `MT Group` },
  { account_name: `time:matters` },
  { account_name: `Artemis DNA` },
  { account_name: `BrainCo` },
  { account_name: `Solugen` },
  { account_name: `Caidya` },
  { account_name: `Calico Life Sciences` },
  { account_name: `Neuralink` },
  { account_name: `William Harvey Research Ltd` },
  { account_name: `HH BioTechnologies` },
  { account_name: `iCD System` },
  { account_name: `IDDI - Biostatistics & eClinical Experts` },
  { account_name: `Inte:Ligand` },
  { account_name: `Pharma Test` },
  { account_name: `Radius Innovation & Development` },
  { account_name: `BioMed X Institute` },
  { account_name: `BigHat Biosciences` },
  { account_name: `Antiverse` },
  { account_name: `Opentrons Labworks` },
  { account_name: `IMP Clinical Supply Services` },
  { account_name: `Bioforum the Data Masters` },
  { account_name: `Zebra Technologies` },
  { account_name: `Ricoh USA` },
  { account_name: `PARI` },
  { account_name: `NAG` },
  { account_name: `GlobalVision` },
  { account_name: `BT Group` },
  { account_name: `Bonds Biosystems` },
  { account_name: `Flow Robotics` },
  { account_name: `Deepcell` },
  { account_name: `Cytomos` },
  { account_name: `Esya Labs` },
  { account_name: `vivoVerse` },
  { account_name: `Kilobaser` },
  { account_name: `Genie Life Sciences` },
  { account_name: `Secoya Technologies` },
  { account_name: `POMICELL` },
  { account_name: `FabRx` },
  { account_name: `Frontier Bio` },
  { account_name: `Cytochroma` },
  { account_name: `Dalriada Drug Discovery` },
  { account_name: `NuChem Sciences` },
  { account_name: `X-Chem` },
  { account_name: `APIS Assay Technologies` },
  { account_name: `Single Cell Discoveries` },
  { account_name: `Droplet Genomics` },
  { account_name: `Pangaea` },
  { account_name: `Scipio Bioscience` },
  { account_name: `Aptuit` },
  { account_name: `theraCUES` },
  { account_name: `ZONTAL` },
  { account_name: `VivaBioCell` },
  { account_name: `Pixelgen Technologies` },
  { account_name: `Xellar Biosystems` },
  { account_name: `Truckee Applied Genomics` },
  { account_name: `Tessara Therapeutics` },
  { account_name: `Stem Pharm` },
  { account_name: `Saguaro Technologies` },
  { account_name: `Probius` },
  { account_name: `Automata` },
  { account_name: `BioReperia` },
  { account_name: `BioNeex` },
  { account_name: `BioCentriq` },
  { account_name: `Cellenion` },
  { account_name: `Sixfold Bioscience` },
  { account_name: `Lightcast Discovery` },
  { account_name: `Terray Therapeutics` },
  { account_name: `Standigm` },
  { account_name: `1859` },
  { account_name: `Absolute Biotech` },
  { account_name: `Creative BioMart` },
  { account_name: `Abterra Biosciences` },
  { account_name: `Whitehead Scientific` },
  { account_name: `Capricorn Scientific` },
  { account_name: `CellRev` },
  { account_name: `Inoviem Scientific` },
  { account_name: `Inventia Life Science` },
  { account_name: `Isomorphic Labs` },
  { account_name: `OpenEye, Cadence Molecular Sciences` },
  { account_name: `Cresset software and discovery` },
  { account_name: `Lykan Bioscience` },
  { account_name: `Ingenza` },
  { account_name: `Immunai` },
  { account_name: `Visual Science` },
  { account_name: `Avectas` },
  { account_name: `Obatala Sciences` },
  { account_name: `AxoSim` },
  { account_name: `CN Bio` },
  { account_name: `BEONCHIP` },
  { account_name: `Tissue Dynamics` },
  { account_name: `Hesperos` },
  { account_name: `CHERRY BIOTECH` },
  { account_name: `4Dcell` },
  { account_name: `Encodia` },
  { account_name: `Seranova Bio` },
  { account_name: `AtlasXomics` },
  { account_name: `Singleron Biotechnologies` },
  { account_name: `81qd` },
  { account_name: `Velsera (formerly Seven Bridges)` },
  { account_name: `CuriRx` },
  { account_name: `Arc Bio` },
  { account_name: `Dovetail Genomics` },
  { account_name: `Censo Biotechnologies` },
  { account_name: `Arima Genomics` },
  { account_name: `Ultima Genomics` },
  { account_name: `Corden Pharma` },
  { account_name: `Watchmaker Genomics` },
  { account_name: `Singular Genomics` },
  { account_name: `A-Alpha Bio` },
  { account_name: `CYTENA` },
  { account_name: `Phasefocus` },
  { account_name: `Komodo Health` },
  { account_name: `ProAxsis` },
  { account_name: `Exploristics` },
  { account_name: `Data4Cure` },
  { account_name: `ALPCO` },
  { account_name: `Iris AI` },
  { account_name: `Chronicled` },
  { account_name: `Qualio` },
  { account_name: `Elemental Machines` },
  { account_name: `nference` },
  { account_name: `Elucidata` },
  { account_name: `Resilience` },
  { account_name: `Interius BioTherapeutics` },
  { account_name: `Arpeggio Biosciences` },
  { account_name: `Menten AI` },
  { account_name: `SpIntellx` },
  { account_name: `OrganaBio` },
  { account_name: `CytoReason` },
  { account_name: `Gandeeva Therapeutics` },
  { account_name: `Ozette Technologies` },
  { account_name: `Seismic Therapeutic` },
  { account_name: `hC Bioscience` },
  { account_name: `Cellanome` },
  { account_name: `Bonum Therapeutics` },
  { account_name: `Ganymede` },
  { account_name: `Concerto Biosciences` },
  { account_name: `64x Bio` },
  { account_name: `BiosPacific` },
  { account_name: `Adare Pharma Solutions` },
  { account_name: `APDM Wearable Technologies` },
  { account_name: `Forecyte Bio Limited` },
  { account_name: `Recursion` },
  { account_name: `DeepLife` },
  { account_name: `A4Cell` },
  { account_name: `SimBioSys` },
  { account_name: `BioCina` },
  { account_name: `GeminiBio` },
  { account_name: `Absci` },
  { account_name: `Beagle Scientific` },
  { account_name: `Kytopen` },
  { account_name: `Secant Group` },
  { account_name: `NETRI` },
  { account_name: `Nortis` },
  { account_name: `Cellbricks` },
  { account_name: `Bi/ond` },
  { account_name: `TissUse` },
  { account_name: `River BioMedics` },
  { account_name: `Symeres` },
  { account_name: `Cergentis` },
  { account_name: `4BioCell` },
  { account_name: `Asimov` },
  { account_name: `ProAnalytics` },
  { account_name: `Symbiosis Pharmaceutical Services` },
  { account_name: `c-LEcta GmbH` },
  { account_name: `Northway Biotech` },
  { account_name: `ExpressCells` },
  { account_name: `Basetwo AI` },
  { account_name: `Lindy Biosciences` },
  { account_name: `Demeetra AgBio` },
  { account_name: `Polysciences` },
  { account_name: `NuProbe` },
  { account_name: `I&L Biosystems` },
  { account_name: `SANI Membranes` },
  { account_name: `TECNIC` },
  { account_name: `MYRIADE` },
  { account_name: `Okomera` },
  { account_name: `Biointron Biological` },
  { account_name: `OmniAb` },
  { account_name: `Applied BioMath` },
  { account_name: `PathoQuest` },
  { account_name: `Parse Biosciences` },
  { account_name: `Abveris` },
  { account_name: `Stilla Technologies` },
  { account_name: `Spark Holland` },
  { account_name: `seqWell` },
  { account_name: `Rhinostics` },
  { account_name: `RareCyte` },
  { account_name: `QbDVision` },
  { account_name: `Protein Dynamic Solutions` },
  { account_name: `Phase Genomics` },
  { account_name: `Erbi Biosystems, a part of MilliporeSigma` },
  { account_name: `Nuclera` },
  { account_name: `Newomics` },
  { account_name: `Nautilus Biotechnology` },
  { account_name: `Mission Bio` },
  { account_name: `MIP Discovery` },
  { account_name: `Radleys` },
  { account_name: `Matterworks` },
  { account_name: `Maravai LifeSciences` },
  { account_name: `Gate Scientific` },
  { account_name: `Fluidic Analytics` },
  { account_name: `FenoLogica Biosciences` },
  { account_name: `evorion biotechnologies` },
  { account_name: `Evonetix` },
  { account_name: `DNA Script` },
  { account_name: `Vyant Bio` },
  { account_name: `Ansa Biotechnologies` },
  { account_name: `Alamar Biosciences` },
  { account_name: `GPE Scientific` },
  { account_name: `Axio Research` },
  { account_name: `Carr Biosystems` },
  { account_name: `FEMTONICS` },
  { account_name: `Systemic Bio, a 3D Systems company` },
  { account_name: `Plasmion` },
  { account_name: `Mandel Scientific` },
  { account_name: `ELGA LabWater` },
  { account_name: `Affinisep` },
  { account_name: `Advanced Materials Technology` },
  { account_name: `BennuBio` },
  { account_name: `Fortis Life Sciences` },
  { account_name: `The Bioinformatics CRO` },
  { account_name: `Mettler-Toledo International` },
  { account_name: `Wheeler Bio` },
  { account_name: `Gamma Biosciences` },
  { account_name: `Carolina Components Group` },
  { account_name: `Rules-Based Medicine a Q2 Solutions Company` },
  { account_name: `Huma.ai` },
  { account_name: `Zephyr AI` },
  { account_name: `ChemScene` },
  { account_name: `Alpenglow Biosciences` },
  { account_name: `iVexSol` },
  { account_name: `Sampled` },
  { account_name: `Jetequip` },
  { account_name: `Zifo` },
  { account_name: `PepLib` },
  { account_name: `Slingshot Biosciences` },
  { account_name: `Brooks Automation` },
  { account_name: `DISPENDIX` },
  { account_name: `TissueVision` },
  { account_name: `Celldom` },
  { account_name: `Element Biosciences` },
  { account_name: `Andelyn Biosciences` },
  { account_name: `TissueLabs` },
  { account_name: `Poietis` },
  { account_name: `ThoughtSpot` },
  { account_name: `Biovia` },
  { account_name: `Specifica, a Q² Solutions Company` },
  { account_name: `Endeavor Consulting Group` },
  { account_name: `FluidForm` },
  { account_name: `Domino Data Lab` },
  { account_name: `PicnicHealth` },
  { account_name: `Antaros Medical` },
  { account_name: `ZEON Specialty Materials` },
  { account_name: `Causaly` },
  { account_name: `Radiomics` },
  { account_name: `Imaris` },
  { account_name: `Emerald Cloud Lab` },
  { account_name: `Paige` },
  { account_name: `Correlia Biosystems` },
  { account_name: `Chemspace` },
  { account_name: `bit.bio` },
  { account_name: `Biorelate` },
  { account_name: `Beckhoff Automation` },
  { account_name: `Araceli Biosciences` },
  { account_name: `Altis Biosystems` },
  { account_name: `acCELLerate` },
  { account_name: `3Brain AG` },
  { account_name: `Celltrio` },
  { account_name: `AlveoliX` },
  { account_name: `Innomar Strategies` },
  { account_name: `PharmEng Technology` },
  { account_name: `Seer` },
  { account_name: `Candex` },
  { account_name: `Brunel` },
  { account_name: `Alpha Controls & Instrumentation` },
  { account_name: `GemPharmatech` },
  { account_name: `Ingenious e-Brain` },
  { account_name: `AWINSA Life Sciences` },
  { account_name: `Immundiagnostik` },
  { account_name: `Scitara Corporation` },
  { account_name: `Accencio` },
  { account_name: `Iktos` },
  { account_name: `Nanome` },
  { account_name: `3M` },
  { account_name: `Prellis Biologics` },
  { account_name: `HUB Organoids` },
  { account_name: `Cellesce` },
  { account_name: `SAMDI Tech` },
  { account_name: `XtalPi` },
  { account_name: `LECO` },
  { account_name: `Muwells` },
  { account_name: `Cadworks3d` },
  { account_name: `Harvard Apparatus` },
  { account_name: `LabSmith` },
  { account_name: `Elveflow Microfluidics | an Elvesys brand` },
  { account_name: `HD Biosciences, a Wuxi company` },
  { account_name: `Swift Scientific` },
  { account_name: `Inscripta` },
  { account_name: `PicoQuant` },
  { account_name: `Abberior` },
  { account_name: `Azure Biosystems` },
  { account_name: `Veranome Biosystems` },
  { account_name: `Visiopharm` },
  { account_name: `Vizgen` },
  { account_name: `ProtaGene` },
  { account_name: `Kindeva Drug Delivery` },
  { account_name: `SEQENS` },
  { account_name: `ANGLE` },
  { account_name: `Taros Chemicals` },
  { account_name: `Publicis Health` },
  { account_name: `BeiGene` },
  { account_name: `Eisai US` },
  { account_name: `FullStory` },
  { account_name: `Arxada` },
  { account_name: `NemaLife` },
  { account_name: `Daikin Chemical Europe` },
  { account_name: `ProteinQure` },
  { account_name: `AARDEX Group` },
  { account_name: `AiCure` },
  { account_name: `Pathcore` },
  { account_name: `MOBILion Systems` },
  { account_name: `HTG Molecular Diagnostics` },
  { account_name: `Insight Global` },
  { account_name: `Takara Bio` },
  { account_name: `Sentauri` },
  { account_name: `Code Ocean` },
  { account_name: `Purigen Biosystems` },
  { account_name: `Nanosyn` },
  { account_name: `Fusemachines` },
  { account_name: `Novasign` },
  { account_name: `Applied Cells` },
  { account_name: `Evogene` },
  { account_name: `DeepCure` },
  { account_name: `InterVenn Biosciences` },
  { account_name: `RoosterBio` },
  { account_name: `ValitaCell` },
  { account_name: `Envisagenics` },
  { account_name: `Insilico Medicine` },
  { account_name: `Freenome` },
  { account_name: `Cyclica` },
  { account_name: `Verge Genomics` },
  { account_name: `BioSymetrics` },
  { account_name: `Yposkesi, an SK pharmteco company` },
  { account_name: `LynxCare` },
  { account_name: `ARL Bio Pharma` },
  { account_name: `Vitas Analytical Services` },
  { account_name: `Averica Discovery Services` },
  { account_name: `Physna` },
  { account_name: `LSInextGen` },
  { account_name: `ProtonMedia` },
  { account_name: `ConvergeOne` },
  { account_name: `O'Reilly Media` },
  { account_name: `RWS Group` },
  { account_name: `SHI International` },
  { account_name: `Smiths Detection` },
  { account_name: `Technology Concepts Group International/BigVar` },
  { account_name: `Tapemark` },
  { account_name: `Verizon Enterprise Solutions` },
  { account_name: `Körber Pharma` },
  { account_name: `Inmark Packaging` },
  { account_name: `H.E.L Group` },
  { account_name: `CTech™ Analytical Solutions - Repligen` },
  { account_name: `Eyen` },
  { account_name: `Refeyn` },
  { account_name: `Ips-Integrated Project Services` },
  { account_name: `Nitto Avecia` },
  { account_name: `Culture Biosciences` },
  { account_name: `Kaneka North America` },
  { account_name: `GoSilico, now part of Cytiva` },
  { account_name: `Yoh, A Day & Zimmermann Company` },
  { account_name: `Collaborative Drug Discovery - CDD VAULT` },
  { account_name: `Snapdragon Chemistry` },
  { account_name: `Anton Paar` },
  { account_name: `Accellix` },
  { account_name: `Tableau Software` },
  { account_name: `Biomere (Biomedical Research Models` },
  { account_name: `Quantiphi` },
  { account_name: `Equflow BV` },
  { account_name: `Resolve BioSciences` },
  { account_name: `IXICO` },
  { account_name: `QMENTA` },
  { account_name: `Zegami` },
  { account_name: `Sema Omics` },
  { account_name: `ACQUIFER Imaging` },
  { account_name: `NeoVirTech` },
  { account_name: `JAGGAER` },
  { account_name: `Prysm Systems` },
  { account_name: `The Autumn Group IT Staffing and Solutions` },
  { account_name: `Reveal Biosciences` },
  { account_name: `Owkin` },
  { account_name: `3P Biopharmaceuticals` },
  { account_name: `DataCamp` },
  { account_name: `Deciphex` },
  { account_name: `Nelson Labs` },
  { account_name: `CPi Technology` },
  { account_name: `EPAM Systems` },
  { account_name: `Tempus Labs` },
  { account_name: `Ibsen Photonics` },
  { account_name: `Wilco AG - CCIT and Visual Inspection Solutions` },
  { account_name: `Atlantic Lab Equipment` },
  { account_name: `XOS (X-Ray Optical Systems)` },
  { account_name: `IonDx` },
  { account_name: `Elemental Analysis` },
  { account_name: `Brechbühler` },
  { account_name: `Cellular Technology Limited — CTL` },
  { account_name: `Daicel Chiral Technologies` },
  { account_name: `Meso Scale Discovery` },
  { account_name: `NBS Scientific USA` },
  { account_name: `Evident Scientific` },
  { account_name: `Center for Breakthrough Medicines` },
  { account_name: `Peak Analysis and Automation` },
  { account_name: `IMI Adaptas` },
  { account_name: `V&P Scientific` },
  { account_name: `Eurofins Viracor` },
  { account_name: `insitro` },
  { account_name: `GE Digital` },
  { account_name: `GEA` },
  { account_name: `Hellma` },
  { account_name: `Sensorex` },
  { account_name: `System-c bioprocess` },
  { account_name: `Frontier Diagnostics` },
  { account_name: `Xtreme Power Conversion` },
  { account_name: `Just - Evotec Biologics` },
  { account_name: `HTX Technologies` },
  { account_name: `Imaging Mass Spectrometry Society (IMSS)` },
  { account_name: `Leukocare` },
  { account_name: `Global Access Dx (GADx) (formerly Mologic)` },
  { account_name: `ProMab Biotechnologies` },
  { account_name: `ABclonal Technology` },
  { account_name: `Deep Lens` },
  { account_name: `WuXi Biologics` },
  { account_name: `ZS` },
  { account_name: `Alpha Analytical` },
  { account_name: `Vironova AB` },
  { account_name: `Cohen Veterans Biosciences` },
  { account_name: `Baxter BioPharma Solutions` },
  { account_name: `Keck Graduate Institute` },
  { account_name: `NEBION is now Immunai` },
  { account_name: `LabVoice` },
  { account_name: `OMass Therapeutics` },
  { account_name: `evitria` },
  { account_name: `SimulTOF Systems` },
  { account_name: `Amsterdam Scientific Instruments` },
  { account_name: `SunChrom` },
  { account_name: `Ionoptika` },
  { account_name: `Syniti` },
  { account_name: `Jiva Science` },
  { account_name: `Nityo Infotech Corporation` },
  { account_name: `Molecular Sensing` },
  { account_name: `Telesis Bio` },
  { account_name: `Core Life Analytics` },
  { account_name: `PhaseView` },
  { account_name: `Fluigent` },
  { account_name: `Turner Imaging Systems` },
  { account_name: `PhenoVista Biosciences` },
  { account_name: `Lunit` },
  { account_name: `Molecubes` },
  { account_name: `Brightonix Imaging` },
  { account_name: `Akoya Biosciences` },
  { account_name: `Imagion Biosystems` },
  { account_name: `Teledyne Princeton Instruments` },
  { account_name: `CytoViva` },
  { account_name: `SonoVol (A PerkinElmer Company)` },
  { account_name: `Radiomedix` },
  { account_name: `illumiSonics` },
  { account_name: `Carestream` },
  { account_name: `Aspect Imaging` },
  { account_name: `Biospace Lab` },
  { account_name: `Milabs` },
  { account_name: `Michelson Diagnostics` },
  { account_name: `Specim Spectral Imaging` },
  { account_name: `Spectral Instruments Imaging` },
  { account_name: `Telops` },
  { account_name: `Qu Biologics` },
  { account_name: `Photon Etc.` },
  { account_name: `Magnetic Insight` },
  { account_name: `Emit Imaging` },
  { account_name: `Double Helix Optics` },
  { account_name: `Codiak Biosciences` },
  { account_name: `CellSight Technologies` },
  { account_name: `United Imaging Healthcare` },
  { account_name: `Purity-IQ` },
  { account_name: `NVIDIA` },
  { account_name: `FlexBiosys` },
  { account_name: `Gubra` },
  { account_name: `Aiforia` },
  { account_name: `IONpath` },
  { account_name: `LifeCanvas Technologies` },
  { account_name: `Vela Sciences` },
  { account_name: `ProtaGene (BioAnalytix)` },
  { account_name: `BioTuring` },
  { account_name: `ReviveMed` },
  { account_name: `Synthace` },
  { account_name: `Oxgene` },
  { account_name: `Cannabotech` },
  { account_name: `Hyasynth Bio` },
  { account_name: `Emerald Scientific` },
  { account_name: `Curaleaf` },
  { account_name: `Cell Microsystems` },
  { account_name: `Canna Care Docs` },
  { account_name: `MGI` },
  { account_name: `Nicoya` },
  { account_name: `InDevR` },
  { account_name: `STEQ America` },
  { account_name: `GlycoPath` },
  { account_name: `Univercells` },
  { account_name: `Xylyx Bio` },
  { account_name: `Braincube` },
  { account_name: `SAPIC` },
  { account_name: `Admera Health` },
  { account_name: `Connexis Search Group` },
  { account_name: `Nucleai` },
  { account_name: `Stago` },
  { account_name: `Inato` },
  { account_name: `Prime Global` },
  { account_name: `PharmaHEALTHLabs` },
  { account_name: `Oxford PharmaGenesis` },
  { account_name: `MedThink SciCom` },
  { account_name: `The Lockwood Group` },
  { account_name: `Indegene` },
  { account_name: `Fishawack Health` },
  { account_name: `Educational Resource Systems` },
  { account_name: `Biocentric` },
  { account_name: `ApotheCom, an Inizio Company` },
  { account_name: `AlphaGroup Medical Communications` },
  { account_name: `HOF Sonderanlagenbau GmbH` },
  { account_name: `Quantics Biostatistics` },
  { account_name: `FUJIFILM Healthcare Americas Corporation` },
  { account_name: `BIOTEM Custom Antibodies & Services` },
  { account_name: `PathAI` },
  { account_name: `Opus Three` },
  { account_name: `Diapharma` },
  { account_name: `DeciBio` },
  { account_name: `Arcinova - A Quotient Sciences business` },
  { account_name: `BioAscent` },
  { account_name: `Sygnature Discovery` },
  { account_name: `NanoCellect BioMedical` },
  { account_name: `Emulate` },
  { account_name: `Adelphi Values` },
  { account_name: `Critical Process Filtration` },
  { account_name: `Proscia` },
  { account_name: `SOPHiA GENETICS` },
  { account_name: `Promepla` },
  { account_name: `Scitech` },
  { account_name: `CellD` },
  { account_name: `N-Zyme Scientifics` },
  { account_name: `Vectorbuilder` },
  { account_name: `RedShiftBio` },
  { account_name: `StemCultures` },
  { account_name: `DRS Daylight Solutions` },
  { account_name: `BrainXell` },
  { account_name: `Cell Line Genetics` },
  { account_name: `Asdaex` },
  { account_name: `Workflow Informatics` },
  { account_name: `Canopy Biosciences - A Bruker Company` },
  { account_name: `Dell Technologies` },
  { account_name: `Sema4` },
  { account_name: `enEvolv` },
  { account_name: `Robert Corrieri Technologies` },
  { account_name: `Intus Biosciences` },
  { account_name: `Delfi Diagnostics` },
  { account_name: `Berkeley Lights` },
  { account_name: `Sanvitra` },
  { account_name: `PHARMASEP` },
  { account_name: `Institute for In Vitro Sciences` },
  { account_name: `YPrime` },
  { account_name: `Siegfried` },
  { account_name: `PRMA Consulting` },
  { account_name: `Medical Data Analytics` },
  { account_name: `DrugDev` },
  { account_name: `Group JBT` },
  { account_name: `INDATECH - Chauvin Arnoux Group` },
  { account_name: `StreamSets` },
  { account_name: `Databiology` },
  { account_name: `Alation` },
  { account_name: `Aseptic Group` },
  { account_name: `DEXSTR` },
  { account_name: `SciBite` },
  { account_name: `DigiM Solution` },
  { account_name: `Waldner` },
  { account_name: `Kalleid` },
  { account_name: `tec5USA` },
  { account_name: `Navigating Cancer` },
  { account_name: `CIOX Health` },
  { account_name: `ConcertAI` },
  { account_name: `Quartic.ai` },
  { account_name: `Savillex` },
  { account_name: `CYBERnano` },
  { account_name: `AcouSort` },
  { account_name: `Rubrik` },
  { account_name: `Gator Bio` },
  { account_name: `Boston Institute of Biotechnology` },
  { account_name: `Photothermal Spectroscopy` },
  { account_name: `TriNova` },
  { account_name: `DrM, Dr. Mueller AG` },
  { account_name: `IsoPlexis` },
  { account_name: `Strain Measurement Devices` },
  { account_name: `LabTwin – Digital Lab Assistant` },
  { account_name: `Topa Thermal` },
  { account_name: `Halo Labs` },
  { account_name: `OCCHIO Instruments` },
  { account_name: `OUAT!` },
  { account_name: `AKTS` },
  { account_name: `Strategikon Pharma` },
  { account_name: `Databricks` },
  { account_name: `Lena Biosciences` },
  { account_name: `Genomenon` },
  { account_name: `Zeta` },
  { account_name: `Golden West Biologicals` },
  { account_name: `PreOmics` },
  { account_name: `Evosep Biosystems` },
  { account_name: `Protein Metrics` },
  { account_name: `InterMetro Industries` },
  { account_name: `YOURWAY` },
  { account_name: `Single Use Support` },
  { account_name: `Teledyne ISCO` },
  { account_name: `AMPAC Analytical` },
  { account_name: `Activated Research` },
  { account_name: `CELLTREAT Scientific Products` },
  { account_name: `Aurora Microplates` },
  { account_name: `Bon Opus Biosciences` },
  { account_name: `Solaris Biotech` },
  { account_name: `Holland Applied Technologies` },
  { account_name: `Anju Software` },
  { account_name: `Marin Scientific Development Company` },
  { account_name: `Mott Corporation` },
  { account_name: `Tekscape` },
  { account_name: `Ventria Bioscience` },
  { account_name: `InVitria` },
  { account_name: `BBI Solutions` },
  { account_name: `Yokogawa Fluence Analytics` },
  { account_name: `Carterra` },
  { account_name: `Steripure` },
  { account_name: `Betatek` },
  { account_name: `BioIntelligence Technologies` },
  { account_name: `Lhasa Limited` },
  { account_name: `MagArray` },
  { account_name: `Lakeside Process Controls` },
  { account_name: `TetraScience` },
  { account_name: `StemoniX` },
  { account_name: `Clyde Biosciences` },
  { account_name: `BenchSci` },
  { account_name: `IsoSciences` },
  { account_name: `Farrar Scientific` },
  { account_name: `Foxx Life Sciences` },
  { account_name: `AcuraBio` },
  { account_name: `MabPlex` },
  { account_name: `Namocell` },
  { account_name: `Astrea Bioseparations` },
  { account_name: `Securecell` },
  { account_name: `Seeq` },
  { account_name: `SONOTEC Ultrasonic Solutions` },
  { account_name: `TeraPore Technologies` },
  { account_name: `Yokogawa` },
  { account_name: `Aqumin Medical` },
  { account_name: `Toronto Research Chemicals` },
  { account_name: `TargetMol` },
  { account_name: `Selleck Chemicals` },
  { account_name: `NanoTag Biotechnologies` },
  { account_name: `Larodan` },
  { account_name: `Immunostep` },
  { account_name: `Cytognos` },
  { account_name: `Bioss Antibodies` },
  { account_name: `Arbor Assays` },
  { account_name: `Agrisera` },
  { account_name: `Absolute Antibody` },
  { account_name: `PrecisionLife` },
  { account_name: `Atlas Antibodies` },
  { account_name: `Biozol` },
  { account_name: `Nashville Biosciences` },
  { account_name: `Zeochem` },
  { account_name: `Abzena` },
  { account_name: `Genovis` },
  { account_name: `Pillar Biosciences` },
  { account_name: `Guardant Health` },
  { account_name: `Aetion` },
  { account_name: `ARC Regulatory` },
  { account_name: `Sify Technologies Limited` },
  { account_name: `HemaCare` },
  { account_name: `PRIORITIS, an IQVIA business` },
  { account_name: `Worldwide Clinical Trials` },
  { account_name: `Level Access` },
  { account_name: `Biorg` },
  { account_name: `New Horizon Biotech` },
  { account_name: `Visikol` },
  { account_name: `Corgenix` },
  { account_name: `Genefron` },
  { account_name: `EPI Excellence` },
  { account_name: `Thermo Fisher Scientific Life Sciences Solutions Group` },
  { account_name: `BioPoint` },
  { account_name: `SSS International Clinical Research` },
  { account_name: `HealthCore` },
  { account_name: `Genesis Research` },
  { account_name: `Digital Health Outcomes` },
  { account_name: `CTI Clinical Trial and Consulting Services` },
  { account_name: `Boston Strategic Partners` },
  { account_name: `TriNetX` },
  { account_name: `Xcenda` },
  { account_name: `STATinMED` },
  { account_name: `OM1` },
  { account_name: `Doctor Evidence` },
  { account_name: `Ipsos Healthcare` },
  { account_name: `RTI Health Solutions` },
  { account_name: `Cisiv` },
  { account_name: `umotif` },
  { account_name: `Adelphi Real World` },
  { account_name: `SmartAnalyst` },
  { account_name: `Clinical Outcomes Solutions` },
  { account_name: `York Health Economics Consortium` },
  { account_name: `Avalon Health Economics` },
  { account_name: `Panalgo` },
  { account_name: `MarksMan Healthcare` },
  { account_name: `Broadstreet Health Economics & Outcomes Research` },
  { account_name: `Complete HEOR Solutions (CHEORS)` },
  { account_name: `Lumanity` },
  { account_name: `Zuellig Pharma` },
  { account_name: `Nextmove Software` },
  { account_name: `The Binding Site` },
  { account_name: `FUJIFILM Holdings America Corporation` },
  { account_name: `Electron Microscopy Sciences` },
  { account_name: `Subangstrom` },
  { account_name: `NECI` },
  { account_name: `Anatrace` },
  { account_name: `MiTeGen` },
  { account_name: `Abilita Bio` },
  { account_name: `Hampton Research` },
  { account_name: `ClickBio` },
  { account_name: `Aera Technology` },
  { account_name: `Malema, a PSG brand` },
  { account_name: `Zenith Technologies` },
  { account_name: `LifeMap Sciences` },
  { account_name: `Lander Analytics` },
  { account_name: `Secureworks` },
  { account_name: `Metrion Biosciences` },
  { account_name: `OmniSeq` },
  { account_name: `Soladis` },
  { account_name: `StayWell (Now Krames)` },
  { account_name: `Medscape` },
  { account_name: `Cell Culture Company` },
  { account_name: `Verily` },
  { account_name: `Enablon` },
  { account_name: `Med-Con Technologies` },
  { account_name: `Filtrox` },
  { account_name: `US Pharmacopeia` },
  { account_name: `Lakeside Life Science` },
  { account_name: `Bioreactor Sciences` },
  { account_name: `Bionova Scientific` },
  { account_name: `Biodextris` },
  { account_name: `Ultivue` },
  { account_name: `Accura Pharmaquip` },
  { account_name: `Resolution Bioscience` },
  { account_name: `Phosphorus` },
  { account_name: `DPT Laboratories` },
  { account_name: `Hematogenix Lab Services` },
  { account_name: `Predictive Oncology` },
  { account_name: `Enzymlogic` },
  { account_name: `BioLamina` },
  { account_name: `Axon Medchem` },
  { account_name: `Sengenics` },
  { account_name: `AB Biotechnologies` },
  { account_name: `Nanoscribe` },
  { account_name: `ETEC` },
  { account_name: `Biomated Solutions` },
  { account_name: `Precision Automation® Company` },
  { account_name: `WorkFusion` },
  { account_name: `Elastic` },
  { account_name: `eMolecules` },
  { account_name: `Westnet` },
  { account_name: `NovaBioAssays` },
  { account_name: `Cytek Biosciences` },
  { account_name: `AroCell` },
  { account_name: `RapidMiner` },
  { account_name: `Knime` },
  { account_name: `Trello` },
  { account_name: `Asana` },
  { account_name: `Evernote` },
  { account_name: `Alooma (acquired by Google)` },
  { account_name: `iCharts` },
  { account_name: `InfoSoft Global` },
  { account_name: `InetSoft` },
  { account_name: `Wolfram|Alpha` },
  { account_name: `ZingChart` },
  { account_name: `DataHero` },
  { account_name: `Plotly` },
  { account_name: `Mestrelab Research` },
  { account_name: `SciNote - Electronic Lab Notebook` },
  { account_name: `Tovatech` },
  { account_name: `SCP Science` },
  { account_name: `New Era Enterprises` },
  { account_name: `Neopharm Labs` },
  { account_name: `LGC Standards` },
  { account_name: `FreeThink Technologies` },
  { account_name: `ES Industries` },
  { account_name: `Atlassian` },
  { account_name: `Nodeum` },
  { account_name: `LucidWorks` },
  { account_name: `Twigkit` },
  { account_name: `SwiftStack` },
  { account_name: `Seagate Technology` },
  { account_name: `Pure Storage` },
  { account_name: `Medable` },
  { account_name: `Phalanx Biotech Group` },
  { account_name: `Priorclave North America` },
  { account_name: `SGS` },
  { account_name: `ChemPass` },
  { account_name: `Corning Life Sciences` },
  { account_name: `Liquid Biosciences` },
  { account_name: `The BioProcess Institute` },
  { account_name: `TransPerfect` },
  { account_name: `AlphaBio` },
  { account_name: `Core Technology Molding Group` },
  { account_name: `Tek-Matic` },
  { account_name: `Synectics` },
  { account_name: `Nucleus Biologics` },
  { account_name: `BioPartners` },
  { account_name: `Prevail InfoWorks` },
  { account_name: `WhatsApp` },
  { account_name: `Quotient Sciences` },
  { account_name: `Grail` },
  { account_name: `Flatiron Health` },
  { account_name: `Nike Datalogue` },
  { account_name: `Conifer Point` },
  { account_name: `LMK Clinical Research Consulting` },
  { account_name: `Novogene` },
  { account_name: `Solvay` },
  { account_name: `Sealed Air` },
  { account_name: `SABIC` },
  { account_name: `RMB Products` },
  { account_name: `PSG, a Dover Company` },
  { account_name: `OETIKER` },
  { account_name: `Jacobs` },
  { account_name: `Burkert Fluid Control Systems` },
  { account_name: `Arkema` },
  { account_name: `SGS Chemical Solutions Laboratories` },
  { account_name: `Famar` },
  { account_name: `VitalTrax` },
  { account_name: `Trajan Scientific and Medical` },
  { account_name: `QuantumBio` },
  { account_name: `Cell Biologics` },
  { account_name: `Vortex Biosciences` },
  { account_name: `SingulOmics` },
  { account_name: `Inspirata` },
  { account_name: `Singota Solutions` },
  { account_name: `Glatt Group` },
  { account_name: `Celemics` },
  { account_name: `Anacura` },
  { account_name: `Indica Labs` },
  { account_name: `Synbio Technologies` },
  { account_name: `Evidera` },
  { account_name: `TAmiRNA` },
  { account_name: `ImmunXperts, a Q² Solutions Company` },
  { account_name: `Elixirgen Scientific` },
  { account_name: `OptraHEALTH` },
  { account_name: `Genestack` },
  { account_name: `Singer Instruments` },
  { account_name: `ELIM Biopharm` },
  { account_name: `Precision NanoSystems` },
  { account_name: `SouthernBiotech` },
  { account_name: `Southern Research` },
  { account_name: `Deep Genomics` },
  { account_name: `Atomwise` },
  { account_name: `CloudMedx` },
  { account_name: `Element Materials Technology` },
  { account_name: `Altogen Labs` },
  { account_name: `Science Exchange` },
  { account_name: `Vium` },
  { account_name: `Ensigna Biosystems` },
  { account_name: `Zyleris Pharmatech` },
  { account_name: `ZyLab` },
  { account_name: `Zycus` },
  { account_name: `Zontec` },
  { account_name: `ZoBio` },
  { account_name: `Zinsser Analytic` },
  { account_name: `Zilliant` },
  { account_name: `ZEBALABS` },
  { account_name: `Yukon Medical` },
  { account_name: `YourEncore` },
  { account_name: `YoungIn Chromass` },
  { account_name: `Yecuris` },
  { account_name: `XS` },
  { account_name: `Icagen` },
  { account_name: `XpressBio` },
  { account_name: `XONEX Relocation` },
  { account_name: `Xerox Corporation` },
  { account_name: `Xerimis` },
  { account_name: `Xenometrix` },
  { account_name: `Xcelris Labs Ltd.` },
  { account_name: `Agarose Bead Technologies (ABT)` },
  { account_name: `Xavo` },
  { account_name: `Writeresult` },
  { account_name: `WoundMatrix` },
  { account_name: `WorldWide Medical Products` },
  { account_name: `World Precision Instruments` },
  { account_name: `Wolters Kluwer` },
  { account_name: `Wolfram` },
  { account_name: `Wolfe Laboratories` },
  { account_name: `Wockhardt UK` },
  { account_name: `WITec` },
  { account_name: `Wisbiomed` },
  { account_name: `Winpak` },
  { account_name: `Wilson Wolf Manufacturing` },
  { account_name: `DWK Life Sciences` },
  { account_name: `Weylchem Corporation` },
  { account_name: `West Pharmaceutical Services` },
  { account_name: `West Corporation` },
  { account_name: `Wensar Weighing Scales` },
  { account_name: `WellDoc` },
  { account_name: `Wayahead Systems` },
  { account_name: `Maplesoft` },
  { account_name: `FUJIFILM Wako Chemicals USA` },
  { account_name: `Wacker Biotech US` },
  { account_name: `VUV Analytics` },
  { account_name: `Vree Health` },
  { account_name: `VR Analytical` },
  { account_name: `Vne Corporation` },
  { account_name: `Vivomicx` },
  { account_name: `Vivo Bio` },
  { account_name: `Vivantis Technologies` },
  { account_name: `Viva Biotech` },
  { account_name: `VITROCELL Systems` },
  { account_name: `Vitas-M Laboratory` },
  { account_name: `Vistalab Technologies` },
  { account_name: `VisiTech International` },
  { account_name: `Vision Engineering` },
  { account_name: `Viscadia` },
  { account_name: `Virusys Corporation` },
  { account_name: `Virscidian` },
  { account_name: `ViridisChem` },
  { account_name: `Viraquest` },
  { account_name: `Vinchem` },
  { account_name: `Vimta Labs Limited` },
  { account_name: `Vigience` },
  { account_name: `Vetter Pharma` },
  { account_name: `Verne Global` },
  { account_name: `Verified Clinical Trials` },
  { account_name: `Veracyte` },
  { account_name: `Venomtech` },
  { account_name: `Vela Diagnostics` },
  { account_name: `Veeda Clinical Research Limited` },
  { account_name: `Vector Laboratories` },
  { account_name: `Vec Technologies` },
  { account_name: `Vapourtec` },
  { account_name: `Vanta Bioscience LC` },
  { account_name: `Vanguard Controls` },
  { account_name: `Validapro` },
  { account_name: `Valiance Partners - An NNIT Group Company` },
  { account_name: `Valgenesis` },
  { account_name: `VICI` },
  { account_name: `Vala Sciences` },
  { account_name: `Vacuubrand` },
  { account_name: `USEReady` },
  { account_name: `USA Scientific` },
  { account_name: `UpScale Microwave` },
  { account_name: `Upm Pharmaceuticals` },
  { account_name: `Univar USA` },
  { account_name: `UNIQSIS` },
  { account_name: `Union Biometrica` },
  { account_name: `Unigene Laboratories` },
  { account_name: `Unifab Corporation` },
  { account_name: `UNeMed Corporation` },
  { account_name: `Ugo Basile` },
  { account_name: `UCT` },
  { account_name: `Ubiquigent` },
  { account_name: `TwistDx` },
  { account_name: `TWD Tradewinds` },
  { account_name: `Tuttnauer` },
  { account_name: `Tunnell Consulting` },
  { account_name: `Tucker-Davis Technologies` },
  { account_name: `TubeWriter` },
  { account_name: `TSE Systems` },
  { account_name: `Troemner` },
  { account_name: `Tritech Research` },
  { account_name: `TriStar Technology Group` },
  { account_name: `Trinity Biotech` },
  { account_name: `Triligent International` },
  { account_name: `Triclinic Labs` },
  { account_name: `Transonic Systems` },
  { account_name: `transOMIC Technologies` },
  { account_name: `Transnetyx` },
  { account_name: `Trana Discovery` },
  { account_name: `Toxikon` },
  { account_name: `Tosoh Bioscience` },
  { account_name: `Torrey Pines Scientific` },
  { account_name: `Topaz Technologies` },
  { account_name: `Tomtec` },
  { account_name: `Tkl Research` },
  { account_name: `Tissue Solutions` },
  { account_name: `TissueGnostics` },
  { account_name: `Thorlabs` },
  { account_name: `Thoren Caging Systems` },
  { account_name: `Thomas Scientific` },
  { account_name: `thinXXS Microtechnology` },
  { account_name: `Thermoelectric Cooling America Corporation` },
  { account_name: `Therapure Biopharma` },
  { account_name: `The Lee Company` },
  { account_name: `The Journal of Precision Medicine` },
  { account_name: `The Baker Company` },
  { account_name: `The Arthur G Russell Company` },
  { account_name: `TetraQ` },
  { account_name: `Tetracore` },
  { account_name: `Tethis` },
  { account_name: `Capgemini Engineering` },
  { account_name: `Terra Universal` },
  { account_name: `TeraFold Biologics` },
  { account_name: `TeraDiscoveries` },
  { account_name: `Telenor` },
  { account_name: `Teknokroma` },
  { account_name: `Ted Pella` },
  { account_name: `Tedor Pharma` },
  { account_name: `Technoflex` },
  { account_name: `Techlab` },
  { account_name: `Tecella` },
  { account_name: `TCI - Tokyo Chemical Industry` },
  { account_name: `Tara Innovations` },
  { account_name: `Talisman Therapeutics` },
  { account_name: `Talend` },
  { account_name: `IQVIA` },
  { account_name: `TagCyx` },
  { account_name: `Taasera` },
  { account_name: `System Biosciences` },
  { account_name: `Systat Software` },
  { account_name: `Sysmex America` },
  { account_name: `Syrris` },
  { account_name: `Synthonix` },
  { account_name: `Synthexis` },
  { account_name: `Synthetech` },
  { account_name: `Syntermed` },
  { account_name: `Syncrosome` },
  { account_name: `Synbiosis` },
  { account_name: `SynBio Consulting` },
  { account_name: `Synaptic Systems` },
  { account_name: `Sympatec` },
  { account_name: `Suvoda` },
  { account_name: `Sutter Instrument` },
  { account_name: `Surmodics` },
  { account_name: `Surgical Theater` },
  { account_name: `Surface Measurement Systems` },
  { account_name: `SureChem` },
  { account_name: `Supercritical Fluid Technologies` },
  { account_name: `Suncoast Clinical Research` },
  { account_name: `Sumo Logic` },
  { account_name: `Studylog Systems` },
  { account_name: `StressMarq Biosciences` },
  { account_name: `Strem Chemicals` },
  { account_name: `Streck` },
  { account_name: `STRATEC Biomedical Systems` },
  { account_name: `Stratasys` },
  { account_name: `Strand Life Sciences` },
  { account_name: `Stoelting Co.` },
  { account_name: `Stiris Research` },
  { account_name: `Stillmeadow` },
  { account_name: `Stevanato Group` },
  { account_name: `Sterling Pharmaceutical Services` },
  { account_name: `Steris` },
  { account_name: `SteriPack Contract Manufacturing` },
  { account_name: `Sterigenics` },
  { account_name: `Stemina Biomarker Discovery` },
  { account_name: `Stembiosys` },
  { account_name: `Stellarnet` },
  { account_name: `Stäubli` },
  { account_name: `STATKING Clinical Services` },
  { account_name: `Stat Ease` },
  { account_name: `Starr Life Sciences` },
  { account_name: `Stanford Photonics` },
  { account_name: `Stainless Fabrication` },
  { account_name: `STaCS DNA` },
  { account_name: `SRI International` },
  { account_name: `SPX FLOW` },
  { account_name: `Spraying Systems` },
  { account_name: `SPI Pharma` },
  { account_name: `Spinco Biotech` },
  { account_name: `Spherion` },
  { account_name: `Sphere Fluidics` },
  { account_name: `Sphaera Pharma` },
  { account_name: `Spectrum Plastics` },
  { account_name: `Spectronics` },
  { account_name: `Spectron Gas Control Systems GmbH` },
  { account_name: `Spectro Analytical Instruments` },
  { account_name: `Spectrix Analytical Services` },
  { account_name: `Spectris plc` },
  { account_name: `Spectral Data Services` },
  { account_name: `SPECTRAFORCE` },
  { account_name: `Spectra Analysis Instruments` },
  { account_name: `Specialty Measurements` },
  { account_name: `Specialty Glass Products` },
  { account_name: `Specac` },
  { account_name: `Spaulding Clinical Research` },
  { account_name: `Sovicell` },
  { account_name: `Sorbent Technologies` },
  { account_name: `Sophion Bioscience` },
  { account_name: `Somru BioScience` },
  { account_name: `Somagenics` },
  { account_name: `SOLVO Biotechnology | A Charles River Company` },
  { account_name: `Solvias` },
  { account_name: `Softgenetics` },
  { account_name: `Sofie` },
  { account_name: `Socorex` },
  { account_name: `Smartflow Technologies` },
  { account_name: `SmarterLab` },
  { account_name: `Smart Consulting Group` },
  { account_name: `Slone Partners` },
  { account_name: `SKAN` },
  { account_name: `Skalar Analytical B.V.` },
  { account_name: `Sistemic` },
  { account_name: `Sirius Automation` },
  { account_name: `SIRION Biotech GmBH` },
  { account_name: `Sino Biological` },
  { account_name: `Simulogic` },
  { account_name: `SimuGen` },
  { account_name: `Silver Lake Research` },
  { account_name: `Silicycle` },
  { account_name: `Menarini Silicon Biosystems` },
  { account_name: `SignalChem Pharmaceuticals` },
  { account_name: `Sight Machine` },
  { account_name: `Siemens Healthineers` },
  { account_name: `Siemens` },
  { account_name: `SIELC Technologies` },
  { account_name: `Sidmap` },
  { account_name: `Shapeways` },
  { account_name: `GenePharma` },
  { account_name: `Shamrock Structures` },
  { account_name: `Inotiv` },
  { account_name: `SETARAM Instrumentation` },
  { account_name: `Servolift L.L.C.` },
  { account_name: `Sequetech Corp` },
  { account_name: `SEQUANI` },
  { account_name: `Sepragen Corporation` },
  { account_name: `Sepax Technologies` },
  { account_name: `Separation Methods Technologies` },
  { account_name: `Sentry Biopharma Services` },
  { account_name: `Sensitech` },
  { account_name: `Senn Chemicals AG` },
  { account_name: `Selexis` },
  { account_name: `Seat Ventilation` },
  { account_name: `Scripps Laboratories` },
  { account_name: `ScreenCell` },
  { account_name: `S-Clinica` },
  { account_name: `Scinomix` },
  { account_name: `Scimedx` },
  { account_name: `Scilligence` },
  { account_name: `Scigilian Software` },
  { account_name: `Scigene Corporation` },
  { account_name: `Scientific Procurement` },
  { account_name: `Scientific Industries` },
  { account_name: `Scientific Computing & Modeling` },
  { account_name: `Scientifica` },
  { account_name: `Scientech` },
  { account_name: `SCIENION` },
  { account_name: `Sciencell Research Laboratories` },
  { account_name: `Scian Services USA` },
  { account_name: `Schulte Bagtainer` },
  { account_name: `SCHOTT Pharma` },
  { account_name: `Scarritt Group` },
  { account_name: `Scarab Genomics` },
  { account_name: `Sbh Sciences` },
  { account_name: `Savino Del Bene` },
  { account_name: `SARSTEDT` },
  { account_name: `Sapio Sciences` },
  { account_name: `SAPIEN Technologies` },
  { account_name: `Sapidyne Instruments` },
  { account_name: `Santa Cruz Biotechnology` },
  { account_name: `Sannova Analytical` },
  { account_name: `Sanguine Biosciences` },
  { account_name: `San Diego Instruments` },
  { account_name: `Samsung Electronics` },
  { account_name: `Samsung Biologics` },
  { account_name: `Salvona` },
  { account_name: `Salimetrics` },
  { account_name: `SAI Life Sciences` },
  { account_name: `Sage Science` },
  {
    account_name: `Trinity Consultants - SafeBridge® Regulatory & Life Sciences Group`,
  },
  { account_name: `Sachem` },
  { account_name: `Sable Systems International` },
  { account_name: `Saama` },
  { account_name: `Rychiger AG` },
  { account_name: `RxLogix` },
  { account_name: `Rütten Engineering` },
  { account_name: `Russell Finex` },
  { account_name: `RT Instruments` },
  { account_name: `Rovisys` },
  { account_name: `Ropack Pharma Solutions` },
  { account_name: `Rondaxe Pharma, L.L.C.` },
  { account_name: `Rockwell Automation` },
  { account_name: `Robertson Microlit Laboratories` },
  { account_name: `Robert Half` },
  { account_name: `Rigol Technologies` },
  { account_name: `Richman Chemical` },
  { account_name: `Ricca Chemical Company` },
  { account_name: `Rheosense` },
  { account_name: `Rheonix` },
  { account_name: `Retrovirox` },
  { account_name: `ReTiSoft` },
  { account_name: `Resyn Biosciences` },
  { account_name: `Resonant Sensors` },
  { account_name: `Resonance Health` },
  { account_name: `Resintech` },
  { account_name: `Researchdx` },
  { account_name: `Research Diets` },
  { account_name: `Research Across America` },
  { account_name: `Reprocell` },
  { account_name: `Rentschler Biopharma` },
  { account_name: `RENOLIT` },
  { account_name: `Renishaw` },
  { account_name: `ReNeuron` },
  { account_name: `Remcon Plastics` },
  { account_name: `Reliance Life Sciences` },
  { account_name: `Regis Technologies` },
  { account_name: `Rees Scientific` },
  { account_name: `Reed-Lane` },
  { account_name: `Recipharm` },
  { account_name: `Rechon Life Science` },
  { account_name: `Recathco` },
  { account_name: `Real Time Genomics` },
  { account_name: `Realbio Technology` },
  { account_name: `ReachBio Research Labs` },
  { account_name: `Raybiotech` },
  { account_name: `Rapid Micro Biosystems` },
  { account_name: `RAPiD Genomics` },
  { account_name: `Rankin Automation` },
  { account_name: `Randox Biosciences` },
  { account_name: `RainDance Technologies` },
  { account_name: `Radiant Sage Ventures` },
  { account_name: `R&D Systems` },
  { account_name: `Quotium` },
  { account_name: `Quorum Technologies` },
  { account_name: `Quidel` },
  { account_name: `Quest Software` },
  { account_name: `Questron Technologies Corp` },
  { account_name: `Quantum Pharmaceuticals SA` },
  { account_name: `Quantum Design` },
  { account_name: `Quantrix` },
  { account_name: `Quanticate` },
  { account_name: `Quanterix` },
  { account_name: `Quansys Biosciences` },
  { account_name: `Quality Lab Accessories` },
  { account_name: `Quality Chemical Laboratories` },
  { account_name: `Quality Biological` },
  { account_name: `Quality Assistance` },
  { account_name: `Quadro Engineering Corp` },
  { account_name: `Postnova FFF` },
  { account_name: `Portable Genomics` },
  { account_name: `Pope Scientific` },
  { account_name: `Polytec` },
  { account_name: `Polyscientific Research and Development Corp.` },
  { account_name: `Polyplus Transfection` },
  { account_name: `PolyPeptide Group` },
  { account_name: `Polestar Technologies` },
  { account_name: `Pointcross Life Sciences` },
  { account_name: `PRF&L` },
  { account_name: `PLUS Diagnostics` },
  { account_name: `Plexon` },
  { account_name: `Platypus Technologies` },
  { account_name: `Plastics One` },
  { account_name: `Plastic Design Corporation` },
  { account_name: `Pisgah Labs` },
  { account_name: `Piramal Pharma Solutions` },
  { account_name: `Pion` },
  { account_name: `Pinnacle Group` },
  { account_name: `Pine Instrument Company` },
  { account_name: `Pickering Laboratories` },
  { account_name: `Phytronix` },
  { account_name: `Physicians Reference Laboratory, L.L.C.` },
  { account_name: `Photonis` },
  { account_name: `Teledyne Photometrics` },
  { account_name: `PhosphoSolutions` },
  { account_name: `PhoenixBio` },
  { account_name: `Phlexglobal, A PharmaLex Company` },
  { account_name: `Phase Holographic Imaging` },
  { account_name: `Pharmazell` },
  { account_name: `Pharmatest Services` },
  { account_name: `Pharmaron` },
  { account_name: `PharmaPack Asia Limited` },
  { account_name: `PharmaNest` },
  { account_name: `Pharma Models` },
  { account_name: `Pharma Medica Research` },
  { account_name: `Pharmalogics Recruiting` },
  { account_name: `PharmaFacts` },
  { account_name: `PharmaEssentia` },
  { account_name: `Pharmadirections` },
  { account_name: `Pharmacircle` },
  { account_name: `Pharmaceutical Outsourcing` },
  { account_name: `Pharmaceutical Development Group` },
  { account_name: `Pharmaceutical Containment Technologies` },
  { account_name: `Personalis` },
  { account_name: `Personal Genome Diagnostics (PGDx)` },
  { account_name: `Persistent Systems` },
  { account_name: `Perritt Laboratories` },
  { account_name: `Perlen Packaging` },
  { account_name: `Perfinity Biosciences` },
  { account_name: `Peptide Specialty Laboratories GmbH` },
  { account_name: `Pelago Bioscience` },
  { account_name: `Peak Scientific` },
  { account_name: `PCI Analytics` },
  { account_name: `Cirdan` },
  { account_name: `Pathogene` },
  { account_name: `Patheon` },
  { account_name: `Particle Technology Group` },
  { account_name: `Particle Measuring Systems` },
  { account_name: `Par Systems` },
  { account_name: `Parr Instrument` },
  { account_name: `Park Systems` },
  { account_name: `Paratechs` },
  { account_name: `Paradigm4` },
  { account_name: `PanPharma Laboratories` },
  { account_name: `Panlabs Biologics` },
  { account_name: `Panacea Technologies` },
  { account_name: `PamGene` },
  { account_name: `Palantir Technologies` },
  { account_name: `Palamatic` },
  { account_name: `Pacific Immunology` },
  { account_name: `PacBio` },
  { account_name: `Pacific Biolabs` },
  { account_name: `Ozgene` },
  { account_name: `Oz Biosciences` },
  { account_name: `Oxford Nanopore Technologies` },
  { account_name: `Oxford Instruments` },
  { account_name: `OXFORD BIOSYSTEMS` },
  { account_name: `Oxford Biomedical Research` },
  { account_name: `Oxeltis` },
  { account_name: `Ovizio Imaging Systems` },
  { account_name: `Ovivo Uk` },
  { account_name: `Overlook Industries` },
  { account_name: `Otava` },
  { account_name: `Orochem Technologies` },
  { account_name: `Orlando Clinical Research Center` },
  { account_name: `Orion Genomics` },
  { account_name: `Orion Corporation` },
  { account_name: `Organovo` },
  { account_name: `Organomation` },
  { account_name: `Organix` },
  { account_name: `Orflo Technologies` },
  { account_name: `Orchard Software` },
  { account_name: `Orbis Clinical` },
  { account_name: `Optimize Technologies` },
  { account_name: `Optima Chemical Group` },
  { account_name: `Optibrium` },
  { account_name: `optek-Danulat` },
  { account_name: `Opotek` },
  { account_name: `Opgen` },
  { account_name: `OpenText` },
  { account_name: `Ontoforce NV` },
  { account_name: `Onorach Clinical` },
  { account_name: `Oncotherapeutics` },
  { account_name: `OncoDNA SA` },
  { account_name: `Oncimmune` },
  { account_name: `Omega Optical` },
  { account_name: `Omega Engineering` },
  { account_name: `Omega Bio-Tek` },
  { account_name: `Olumia Life` },
  { account_name: `Olink Proteomics` },
  { account_name: `Oligo Factory` },
  { account_name: `Oligoengine` },
  { account_name: `Okolab` },
  { account_name: `Oblong Industries` },
  { account_name: `Objective Imaging` },
  { account_name: `Obiter Research` },
  { account_name: `Oasis Diagnostics` },
  { account_name: `O'Hara Technologies` },
  { account_name: `Nvigen` },
  { account_name: `Nuvisan` },
  { account_name: `Digital.ai` },
  { account_name: `Numenta` },
  { account_name: `Numedii` },
  { account_name: `Nu Instruments` },
  { account_name: `NUCLEICS` },
  { account_name: `Nuaire` },
  { account_name: `NT-MDT` },
  { account_name: `NSI Lab Solution` },
  { account_name: `Noxilizer` },
  { account_name: `Novus Biologicals` },
  { account_name: `Novozymes` },
  { account_name: `Novatia` },
  { account_name: `Novasep` },
  { account_name: `NovAlix` },
  { account_name: `Nosco` },
  { account_name: `Norwich Pharmaceuticals` },
  { account_name: `North Carolina Clinical Research` },
  { account_name: `NAMSA` },
  { account_name: `Norgen Biotek` },
  { account_name: `Nordson` },
  { account_name: `Nordic Biosite` },
  { account_name: `Norac` },
  { account_name: `Nobel Ilac` },
  { account_name: `Nisso America` },
  { account_name: `Nintex` },
  { account_name: `Nextrials` },
  { account_name: `NextPharma` },
  { account_name: `Genuity Science` },
  { account_name: `Next Breath` },
  { account_name: `Next Advance` },
  { account_name: `New Objective` },
  { account_name: `Neurotrials Research` },
  { account_name: `Neurotar` },
  { account_name: `NEUROSOLUTIONS` },
  { account_name: `NeuroScience Associates` },
  { account_name: `NeuroNexus` },
  { account_name: `Neuralynx` },
  { account_name: `NeuralWare` },
  { account_name: `Neuland Laboratories` },
  { account_name: `NeoVentures Biotechnology` },
  { account_name: `Neogen Corporation` },
  { account_name: `NeaSpec` },
  { account_name: `Natoli Engineering Company` },
  { account_name: `Nation Ford Chemical Company` },
  { account_name: `nanoTherics` },
  { account_name: `Nanoscreen` },
  { account_name: `Nanoscience Instruments` },
  { account_name: `Nanonics Imaging` },
  { account_name: `Nanomagnetics Instruments-Usa` },
  { account_name: `Nanoandmore` },
  { account_name: `Nanion Technologies` },
  { account_name: `Nanalysis` },
  { account_name: `Nacalai Usa` },
  { account_name: `Nabsys` },
  { account_name: `MZ Diagnostics` },
  { account_name: `Myriad Genetics` },
  { account_name: `Mu Sigma` },
  { account_name: `Murty Pharmaceuticals` },
  { account_name: `Murigenics` },
  { account_name: `Munevar & Associates` },
  { account_name: `Inceptua Group` },
  { account_name: `Multicase` },
  { account_name: `MR Solutions` },
  { account_name: `MRIGlobal` },
  { account_name: `MP Biomedicals` },
  { account_name: `Mouse Specifics` },
  { account_name: `Morrison Container Handling Solutions` },
  { account_name: `Moltox` },
  { account_name: `Molsoft` },
  { account_name: `Molecular Targeting Technologies` },
  { account_name: `Molecular Pathology Laboratory Network` },
  { account_name: `Molecular Loop` },
  { account_name: `Molecular Health` },
  { account_name: `Molecular Dimensions` },
  { account_name: `Molecular Connections` },
  { account_name: `Molecular Biology Resources` },
  { account_name: `MOgene` },
  { account_name: `MÖLLER Medical` },
  { account_name: `Modwave` },
  { account_name: `Modern Water` },
  { account_name: `Mitchell Martin` },
  { account_name: `Mirimus` },
  { account_name: `Schott MINIFAB` },
  { account_name: `Minakem` },
  { account_name: `Mimetas` },
  { account_name: `Millrock Technology` },
  { account_name: `Mill Creek Life Sciences` },
  { account_name: `Millar` },
  { account_name: `Milestone - Helping Chemists` },
  { account_name: `Mikron Automation` },
  { account_name: `Mikart` },
  { account_name: `Middough` },
  { account_name: `MicroVacuum` },
  { account_name: `Microtrace` },
  { account_name: `Microsonic Systems` },
  { account_name: `Microsolv Technology` },
  { account_name: `Microsaic Systems` },
  { account_name: `Micro Photonics` },
  { account_name: `Micronova Manufacturing` },
  { account_name: `Micronit` },
  { account_name: `Micronic` },
  { account_name: `MicroMatrices` },
  { account_name: `MicroMath Research` },
  { account_name: `Microlit` },
  { account_name: `MICRO HYDRAULICS` },
  { account_name: `Micro Focus` },
  { account_name: `Microdysis` },
  { account_name: `Microbiologics` },
  { account_name: `Microbac Laboratories` },
  { account_name: `Microarrays` },
  { account_name: `MG America` },
  { account_name: `Metrum Research Group` },
  { account_name: `Metkinen Chemistry` },
  { account_name: `MetaSystems International` },
  { account_name: `Mesa Laboratories` },
  { account_name: `Meros Polymers` },
  { account_name: `Melior Discovery` },
  { account_name: `Medtrials` },
  { account_name: `Medqia` },
  { account_name: `Medpharm` },
  { account_name: `Medpace` },
  { account_name: `Medix Biochemica` },
  { account_name: `Mediso Medical Imaging Systems` },
  { account_name: `MediSapiens` },
  { account_name: `Medicor Research` },
  { account_name: `Medichem S.A.` },
  { account_name: `Media Cybernetics` },
  { account_name: `Medelis` },
  { account_name: `Med Associates` },
  { account_name: `McKinsey & Company` },
  { account_name: `McKinley Scientific` },
  { account_name: `McKesson` },
  { account_name: `The McCrone Group` },
  { account_name: `MBRAUN` },
  { account_name: `MBL International` },
  { account_name: `Mayflower Bioscience` },
  { account_name: `MaxCyte` },
  { account_name: `Maverix Biomics` },
  { account_name: `Masipack North America` },
  { account_name: `Masimo` },
  { account_name: `Marshfield Labs` },
  { account_name: `Watson-Marlow Fluid Technology Solutions` },
  { account_name: `MarkLogic` },
  { account_name: `Markes International` },
  { account_name: `Marken` },
  { account_name: `Marin Biologic Laboratories` },
  { account_name: `Marchesini Group S.p.A.` },
  { account_name: `Navitas Life Sciences` },
  { account_name: `Mangan` },
  { account_name: `Magritek` },
  { account_name: `Machaon Diagnostics` },
  { account_name: `Mabtech` },
  { account_name: `Exyte` },
  { account_name: `Lyophilization Technology` },
  { account_name: `LSNE Contract Manufacturing` },
  { account_name: `Lw Scientific` },
  { account_name: `LUX Biotechnology` },
  { account_name: `Lupin Pharmaceuticals` },
  { account_name: `Lumetics` },
  { account_name: `Lumesse` },
  { account_name: `Lumenogix` },
  { account_name: `Ludger` },
  { account_name: `Louston International` },
  { account_name: `Lomir Biomedical` },
  { account_name: `Logos Biosystems` },
  { account_name: `Logan Instruments` },
  { account_name: `Locus Technology` },
  { account_name: `Loba Chemie` },
  { account_name: `Litron Laboratories` },
  { account_name: `Lipoid` },
  { account_name: `Lighthouse Worldwide Solutions` },
  { account_name: `Lighthouse Instruments` },
  { account_name: `Lifesensors` },
  { account_name: `Lifescale Analytics` },
  { account_name: `Lifeline Cell Technology` },
  { account_name: `Life Chemicals` },
  { account_name: `LiCONiC` },
  { account_name: `LGC` },
  { account_name: `Lexalytics, an InMoment Company` },
  { account_name: `Leistritz Corp` },
  { account_name: `Leinco Technologies` },
  { account_name: `Leica Microsystems` },
  { account_name: `Lee Industries` },
  { account_name: `LC Sciences` },
  { account_name: `LCI Corporation International` },
  { account_name: `Laviana Corporation` },
  { account_name: `Laporte Consultants` },
  { account_name: `Ivanti` },
  { account_name: `LAMPIRE Biological Laboratories` },
  { account_name: `Lambda Laboratory Instruments` },
  { account_name: `Lafayette Instrument Company` },
  { account_name: `Labvantage` },
  { account_name: `Labtech` },
  { account_name: `Lab Services B.V.` },
  { account_name: `LABRepCo` },
  { account_name: `Lab Products` },
  { account_name: `LabMinds` },
  { account_name: `Labmate Asia` },
  { account_name: `LABMAN AUTOMATION` },
  { account_name: `Lablynx` },
  { account_name: `Lablogic Systems` },
  { account_name: `Labguru` },
  { account_name: `Labex of MA` },
  { account_name: `Lab Escape` },
  { account_name: `LabConnect` },
  { account_name: `Labconco` },
  { account_name: `Kuraray Chemical Division` },
  { account_name: `Kuhner Shaker` },
  { account_name: `Kryosphere` },
  { account_name: `Kraft Chemical Company` },
  { account_name: `Korsch America` },
  { account_name: `Kore Technology` },
  { account_name: `Koneksa` },
  { account_name: `Komtur Pharmaceuticals` },
  { account_name: `Knoell` },
  { account_name: `KNF Neuberger` },
  { account_name: `KNAUER` },
  { account_name: `Kiyatec` },
  { account_name: `Kinnakeet Biotechnology` },
  { account_name: `Kingfisher Biotech` },
  { account_name: `Kinexum` },
  { account_name: `Kinematic Automation` },
  { account_name: `Kinematica` },
  { account_name: `Kimberly-Clark` },
  { account_name: `Kibron` },
  { account_name: `Key Organics` },
  { account_name: `Key International` },
  { account_name: `Kewaunee Scientific Corporation` },
  { account_name: `Kerafast, a sister company of Absolute Antibody` },
  { account_name: `Kent Systems` },
  { account_name: `Kent Scientific Corporation` },
  { account_name: `Kendrick Laboratories` },
  { account_name: `Kemwell Biopharma` },
  { account_name: `Keen Compressed Gas Co.` },
  { account_name: `KCAS Bioanalytical & Biomarker Services` },
  { account_name: `Kardex` },
  { account_name: `Cerner Enviza` },
  { account_name: `Kanomax USA` },
  { account_name: `Kamiya Biomedical Company` },
  { account_name: `Kahle Automation` },
  { account_name: `Jubilant Ingrevia` },
  { account_name: `Jubilant HollisterStier Contract Manufacturing & Services` },
  { account_name: `Jubilant Biosys` },
  { account_name: `JSI Medical Systems` },
  { account_name: `JRS Pharma` },
  { account_name: `Joule Clinical Staffing Solutions` },
  { account_name: `Jost Chemical Co.` },
  { account_name: `Jordi Labs` },
  { account_name: `JM Science` },
  { account_name: `J-Kem Scientific` },
  { account_name: `Jetpharma` },
  { account_name: `JEOL` },
  { account_name: `Jenkem Technology USA` },
  { account_name: `Jenike & Johanson` },
  { account_name: `Jeiven Pharmaceutical Consulting` },
  { account_name: `JAY INSTRUMENTS AND SYSTEMS` },
  { account_name: `James Alexander Corporation` },
  { account_name: `Jali Medical` },
  { account_name: `Jade Scientific` },
  { account_name: `Jackson ImmunoResearch` },
  { account_name: `IXRF Systems` },
  { account_name: `iSpecimen` },
  { account_name: `Isogen Life Sciences` },
  { account_name: `Isogenica` },
  { account_name: `FUJIFILM Irvine Scientific` },
  { account_name: `IRIS Biotech` },
  { account_name: `Irepertoire` },
  { account_name: `iPrasense` },
  { account_name: `IPQ Analytics` },
  { account_name: `Ionscope` },
  { account_name: `IO Informatics` },
  { account_name: `Invivotek` },
  { account_name: `Invivoscribe Technologies` },
  { account_name: `InvivoSciences` },
  { account_name: `InvivoGen` },
  { account_name: `Invitrox` },
  { account_name: `Invicro` },
  { account_name: `Invetech` },
  { account_name: `Investigator Support Services` },
  { account_name: `Intervivo Solutions` },
  { account_name: `Intersystems` },
  { account_name: `Interpretomics` },
  { account_name: `Interchem Corporation` },
  { account_name: `Intelligen` },
  { account_name: `Intelent` },
  { account_name: `Integrium Clinical Research` },
  { account_name: `IntegriChain` },
  { account_name: `Integrated Therapeutic Solutions` },
  { account_name: `Integrated Fluidics` },
  { account_name: `Integrated Biotherapeutics` },
  { account_name: `Integrated Analytical Solution` },
  { account_name: `Integrated Analytical Laboratories` },
  { account_name: `Instem` },
  { account_name: `Inscopix` },
  { account_name: `In-Position Technologies` },
  { account_name: `Inovatia Laboratories` },
  { account_name: `Inovalon` },
  { account_name: `INovacia` },
  { account_name: `Sysmex Inostics` },
  { account_name: `Innovive` },
  { account_name: `Innovatum` },
  { account_name: `Innovative Biochips` },
  { account_name: `Innoprot` },
  { account_name: `Fujirebio` },
  { account_name: `Innobiochips` },
  { account_name: `INFORS HT` },
  { account_name: `Informetric` },
  { account_name: `infoQuant` },
  { account_name: `Infometrix` },
  { account_name: `Indus Instruments` },
  { account_name: `Indofine Chemical Company` },
  { account_name: `Indigo BioAutomation` },
  { account_name: `Indicia` },
  { account_name: `INDEAR` },
  { account_name: `Inamed Gmbh` },
  { account_name: `Imquest Biosciences` },
  { account_name: `Importfab` },
  { account_name: `Implen U.S.A.` },
  { account_name: `Imperial Clinical Research Services` },
  { account_name: `Immunovia AB` },
  { account_name: `Immunoreagents` },
  { account_name: `Immunetrics` },
  { account_name: `IMA Group` },
  { account_name: `Imagexpert` },
  { account_name: `ImaBiotech` },
  { account_name: `ILC Dover` },
  { account_name: `Ikonisys` },
  { account_name: `Iedco Corp` },
  { account_name: `IDT Biologika` },
  { account_name: `IDEXX BioAnalytics` },
  { account_name: `IDEA Bio-Medical` },
  {
    account_name: `ICQ Consultants (Integrated Commissioning & Qualification Corp.)`,
  },
  { account_name: `Icometrix` },
  { account_name: `ICIMS` },
  { account_name: `ibidi` },
  { account_name: `Iadvantage Software` },
  { account_name: `Hycult Biotech` },
  { account_name: `Huxley Bertram` },
  { account_name: `Hudson Robotics` },
  { account_name: `HTD Biosystems` },
  { account_name: `Howorth Air Tech` },
  { account_name: `Hovione` },
  { account_name: `Hootsuite` },
  { account_name: `Honeywell` },
  { account_name: `Holloway America` },
  { account_name: `Hitech Instruments` },
  { account_name: `HistoWiz` },
  { account_name: `HISTOLOGIX` },
  { account_name: `Histocell` },
  { account_name: `Hilltop Lab Animals` },
  { account_name: `Hiden Isochema Ltd` },
  { account_name: `Hiden Analytical` },
  { account_name: `Hettich Instruments` },
  { account_name: `Heraeus Noblelight` },
  { account_name: `Hemogenix` },
  { account_name: `Hemco Corporation` },
  { account_name: `Hello Bio` },
  { account_name: `Helix Biomedix` },
  { account_name: `Helena Laboratories Corporation` },
  { account_name: `Heinkel Filtering Systems` },
  { account_name: `Heidolph` },
  { account_name: `HEIDENHAIN` },
  { account_name: `Heathrow Scientific` },
  { account_name: `Health Technology Networks` },
  { account_name: `Headwall` },
  { account_name: `Hawaii Biotech` },
  { account_name: `Aenova Group` },
  { account_name: `Haselmeier` },
  { account_name: `Harvard Bioscience` },
  { account_name: `HARMONIC PHARMA - SAFETY BY DESIGN®` },
  { account_name: `Harmonic Drive` },
  { account_name: `Hanna Instruments` },
  { account_name: `Hamilton Thorne` },
  { account_name: `Hach` },
  { account_name: `Gyma Laboratories of America` },
  { account_name: `Gyger Fluidics GmbH` },
  { account_name: `GVS Filter Technology` },
  { account_name: `Grenova` },
  { account_name: `Greiner Bio-One` },
  { account_name: `Greenwood Products` },
  { account_name: `Green Mountain Antibodies` },
  { account_name: `Great Lakes Neurotechnologies` },
  { account_name: `Greatglas` },
  { account_name: `Gray Matter Research` },
  { account_name: `GraphPad` },
  { account_name: `Grant Instruments` },
  { account_name: `Grand River Aseptic Manufacturing` },
  { account_name: `Grace Bio-Labs` },
  { account_name: `Golden Helix` },
  { account_name: `Gnubio` },
  { account_name: `Aitia` },
  { account_name: `Glsynthesis` },
  { account_name: `Workhuman` },
  { account_name: `Globepharma` },
  { account_name: `Global IDS` },
  { account_name: `Glen Research` },
  { account_name: `GL Chemtec International Limited` },
  { account_name: `Gland Pharma Limited` },
  { account_name: `Gigahertz-Optik` },
  { account_name: `Gibson Engineering Co` },
  { account_name: `Getz Pharma` },
  { account_name: `Gerteis Maschinen + Processengineering` },
  { account_name: `Gerstel` },
  { account_name: `Gerresheimer` },
  { account_name: `Germfree` },
  { account_name: `German Mouse Clinic (GMC)` },
  { account_name: `Genus Biosystems` },
  { account_name: `GENTRONIX` },
  { account_name: `Gentech Scientific` },
  { account_name: `genOway` },
  { account_name: `GenoSpace` },
  { account_name: `Genosensor` },
  { account_name: `GENOMIC VISION` },
  { account_name: `Genomica` },
  { account_name: `Genome Explorations` },
  { account_name: `Genlantis Diagnostics` },
  { account_name: `Genhunter Corp` },
  { account_name: `GeneWorks` },
  { account_name: `Geneva Scientific` },
  { account_name: `Genetex` },
  { account_name: `Genesee Scientific` },
  { account_name: `Generation Biotech` },
  { account_name: `General Metabolics` },
  { account_name: `General Biologicals Corp. (GBC)` },
  { account_name: `Genepep` },
  { account_name: `Genemed Biotechnologies` },
  { account_name: `Gene Link` },
  { account_name: `Genefluidics` },
  { account_name: `GeneExpression Systems` },
  { account_name: `Genecopoeia` },
  { account_name: `Gene Codes Corporation` },
  { account_name: `GeneCentric Diagnostics` },
  { account_name: `Ginkgo Bioworks` },
  { account_name: `Gel Laboratories` },
  { account_name: `G-Con` },
  { account_name: `Gaylord Chemical Company, L.L.C.` },
  { account_name: `Gateway Analytical` },
  { account_name: `Gatan` },
  { account_name: `Garvey Corporation` },
  { account_name: `Gartner` },
  { account_name: `Galbraith Laboratories` },
  { account_name: `G&G Technologies` },
  { account_name: `Fusion Antibodies` },
  { account_name: `Fujitsu` },
  { account_name: `FUJIFILM Diosynth Biotechnologies` },
  { account_name: `Frost & Sullivan` },
  { account_name: `Frontage Laboratories` },
  { account_name: `FRITSCH Milling and Sizing Germany` },
  { account_name: `Freund-Vector` },
  { account_name: `Freudenberg Medical` },
  { account_name: `Fresenius Kabi` },
  { account_name: `Freeman Technology` },
  { account_name: `Foundation Medicine` },
  { account_name: `Fortis Technologies` },
  { account_name: `Formurex` },
  { account_name: `Formulator Software` },
  { account_name: `Formulaction` },
  { account_name: `Formlabs` },
  { account_name: `Formedix` },
  { account_name: `FORMASPACE` },
  { account_name: `FocalCXM` },
  { account_name: `Fluxion Biosciences` },
  { account_name: `Fluorescence Innovations` },
  { account_name: `Fluor Corporation` },
  { account_name: `Fluke Corporation` },
  { account_name: `Fluid Management Systems` },
  { account_name: `FluidDA` },
  { account_name: `Fluid Air` },
  { account_name: `Flow Sciences` },
  { account_name: `Flownamics` },
  { account_name: `FlowJo` },
  { account_name: `Flow Contract Site Laboratory` },
  { account_name: `FlexLink` },
  { account_name: `Flagship Biosciences` },
  { account_name: `Fios Genomics` },
  { account_name: `Fine Science Tools` },
  { account_name: `Fillab` },
  { account_name: `Filamatic` },
  { account_name: `Fibercell Systems` },
  { account_name: `Fhc` },
  { account_name: `Fette Compacting America` },
  { account_name: `Festo` },
  { account_name: `Fermion` },
  { account_name: `F-DGSi` },
  { account_name: `Faubel Pharma Services` },
  { account_name: `Farm, A Flex Company` },
  { account_name: `Fareva` },
  { account_name: `Facebook` },
  { account_name: `Fabgennix` },
  { account_name: `Extedo` },
  { account_name: `ExScientia` },
  { account_name: `ExpreS2ion Biotechnologies` },
  { account_name: `Exosome Diagnostics` },
  { account_name: `Exergy` },
  { account_name: `Excellims Corporation` },
  { account_name: `ExcellGene` },
  { account_name: `Exalpha Biologicals` },
  { account_name: `Exagen Diagnostics` },
  { account_name: `Exact Sciences` },
  { account_name: `Evonik Corporation` },
  { account_name: `Evolution Scientific` },
  { account_name: `Everest Clinical Research` },
  { account_name: `Everest Biotech` },
  { account_name: `Evercyte` },
  { account_name: `EVEON` },
  { account_name: `Eutech Scientific Services` },
  { account_name: `Product Safety Labs` },
  { account_name: `Eurofins Genomics` },
  { account_name: `Eurobiomed` },
  { account_name: `EUCODIS Bioscience` },
  { account_name: `Eton Bioscience` },
  { account_name: `ETC Sterilization Systems` },
  { account_name: `Etaluma` },
  { account_name: `EST Analytical` },
  { account_name: `ERWEKA` },
  { account_name: `ErtelAlsop` },
  { account_name: `Ericsson` },
  { account_name: `EquipNet` },
  { account_name: `Eprogen` },
  { account_name: `Epoch Life Science` },
  { account_name: `Epistem` },
  { account_name: `Epigentek` },
  { account_name: `Epigenomics` },
  { account_name: `EpigenDx` },
  { account_name: `EpiCypher` },
  { account_name: `Epic Sciences` },
  { account_name: `Enzyme Research Laboratories` },
  { account_name: `Enzon Pharmaceuticals` },
  { account_name: `Envision Pharma Group` },
  { account_name: `Engineering Systems` },
  { account_name: `Engenius Consulting Group` },
  { account_name: `Endress+Hauser` },
  { account_name: `Endpoint Clinical` },
  { account_name: `Encore Research Group` },
  { account_name: `Encompass Pharmaceutical Services` },
  { account_name: `EN-CAS Analytical Laboratories` },
  { account_name: `ENAMINE` },
  { account_name: `Empire Genomics` },
  { account_name: `EmpiraMed` },
  { account_name: `EMOTIV` },
  { account_name: `Emka Technologies` },
  { account_name: `Eminent Services Corporation` },
  { account_name: `Emerson Automation Solutions` },
  { account_name: `Emergent Biosolutions` },
  { account_name: `Embi Tec` },
  { account_name: `Elsohly Laboratories` },
  { account_name: `Ellutia` },
  { account_name: `Elizabeth Companies` },
  { account_name: `Elemental Scientific` },
  { account_name: `Eldon James Corp.` },
  { account_name: `EKF Diagnostics` },
  { account_name: `Ekam Imaging` },
  { account_name: `Eigenvector Research` },
  { account_name: `EGFIE` },
  { account_name: `Egeen International Corp` },
  { account_name: `Edmund Optics` },
  { account_name: `Edax` },
  { account_name: `eClinical Solutions` },
  { account_name: `Echelon Biosciences` },
  { account_name: `Eberbach Corporation` },
  { account_name: `Eastman Chemical Company` },
  { account_name: `Eagle Genomics` },
  { account_name: `Eagle Biosciences` },
  { account_name: `Dynex Technologies` },
  { account_name: `Dynamic Devices` },
  { account_name: `Dynamic Air` },
  { account_name: `Dynachem` },
  { account_name: `Dyadic International` },
  { account_name: `Dwyer Instruments` },
  { account_name: `Durex Industries` },
  { account_name: `Duoject Medical Systems` },
  { account_name: `Duck Flats Pharma` },
  { account_name: `Dsg` },
  { account_name: `Drummond Scientific Company` },
  { account_name: `Drug Plastics Group` },
  { account_name: `Drug Discovery & Development` },
  { account_name: `Driam USA` },
  { account_name: `Doximity` },
  { account_name: `Dovel Technologies` },
  { account_name: `Douglas Instruments` },
  { account_name: `Dottikon Exclusive Synthesis A.G.` },
  { account_name: `DOT Scientific` },
  { account_name: `Dolomite Microfluidics` },
  { account_name: `Dolomite Bio` },
  { account_name: `Dojindo Molecular Technologies` },
  { account_name: `DNAnexus` },
  { account_name: `DM Clinical Research` },
  { account_name: `Diversigen` },
  { account_name: `Cellular Life Sciences` },
  { account_name: `DiaSorin` },
  { account_name: `Diagenode, a Hologic company` },
  { account_name: `Diaclone - Part of Medix Biochemica Group` },
  { account_name: `Diaceutics` },
  { account_name: `Diacarta` },
  { account_name: `Detroit R&D` },
  { account_name: `DeNovix` },
  { account_name: `Delong America` },
  { account_name: `Decision Resources Group (DRG)` },
  { account_name: `DecisionQ` },
  { account_name: `Daxtra Technologies` },
  { account_name: `DavosPharma` },
  { account_name: `David Kopf Instruments` },
  { account_name: `Datwyler Pharma Packaging USA` },
  { account_name: `Datawave Technologies` },
  { account_name: `Datameer` },
  { account_name: `DataApex` },
  { account_name: `Darwin Chambers Company` },
  { account_name: `Dantec Dynamics` },
  { account_name: `Danaher` },
  { account_name: `Dalton Pharma Services` },
  { account_name: `Dabur Research Foundation` },
  { account_name: `Cyviz` },
  { account_name: `Cytovance Biologics` },
  { account_name: `Cytoskeleton` },
  { account_name: `Cyth Systems` },
  { account_name: `Cytel` },
  { account_name: `Cygnus Technologies` },
  { account_name: `CyberGene AB` },
  { account_name: `Cyagen Biosciences` },
  { account_name: `Custom Sensors & Technology` },
  { account_name: `Custom Processing Services` },
  { account_name: `Curetis AG` },
  { account_name: `Cube Biotech` },
  { account_name: `Crystal Pharmatech` },
  { account_name: `Crystalgen` },
  { account_name: `Cryoport` },
  { account_name: `CRS Clinical Research Services` },
  { account_name: `Croda` },
  { account_name: `Signant Health` },
  { account_name: `Creatv MicroTech` },
  { account_name: `Creative Proteomics` },
  { account_name: `Creative Genomics` },
  { account_name: `Creative Design and Machine` },
  { account_name: `Creative Biolabs` },
  { account_name: `CreaGen` },
  { account_name: `Craic Technologies` },
  { account_name: `Agilex Biolabs` },
  { account_name: `CP Lab Safety` },
  { account_name: `Cozzoli Machine Company` },
  { account_name: `Cowie Technology` },
  { account_name: `Covectra` },
  { account_name: `CovalX` },
  { account_name: `Covalab` },
  { account_name: `Cotter Brothers Corporation` },
  { account_name: `Cosmo Bio USA` },
  { account_name: `Cornerstone OnDemand` },
  { account_name: `Coriolis Pharma` },
  { account_name: `CoreRx` },
  { account_name: `Corepharma` },
  { account_name: `Corealis Pharma` },
  { account_name: `Copley Scientific` },
  { account_name: `Contract Pharmaceuticals Limited` },
  { account_name: `Contract Pharmacal Corp` },
  { account_name: `Contipro Biotech` },
  { account_name: `Continental Disc Corporation` },
  { account_name: `Contec Cleanroom` },
  { account_name: `Consolidated Sterilizer Systems` },
  { account_name: `Congenica` },
  { account_name: `Compumedics Usa` },
  { account_name: `CompuDrug International` },
  { account_name: `Complete Genomics` },
  { account_name: `CompChem Solutions` },
  { account_name: `Comparative Biosciences` },
  { account_name: `CAI` },
  { account_name: `CombiPhos Catalysts` },
  { account_name: `Comar` },
  { account_name: `Columnex` },
  { account_name: `Colorcon` },
  { account_name: `Antylia Scientific` },
  { account_name: `Cofactor Genomics` },
  { account_name: `Codex BioSolutions` },
  { account_name: `Coastal Carolina Research Center` },
  { account_name: `AGC Biologics` },
  { account_name: `ClorDiSys Solutions` },
  { account_name: `Clintec` },
  { account_name: `Clin-Pharm` },
  { account_name: `Clininfo` },
  { account_name: `Clinical Research Consulting` },
  { account_name: `Clinical Reference Laboratory` },
  { account_name: `Clinexa Life Sciences` },
  { account_name: `Clindatrix` },
  { account_name: `Cleaver Scientific` },
  { account_name: `ClearH2O` },
  { account_name: `ClearDATA` },
  { account_name: `CRB` },
  { account_name: `Clariness` },
  { account_name: `Civentichem` },
  { account_name: `CitiusTech` },
  { account_name: `Citeline` },
  { account_name: `Cirrus Insight` },
  { account_name: `Cincinnati Test Systems` },
  { account_name: `Ciloa` },
  { account_name: `Chrysalis Pharma Partners` },
  { account_name: `ChromSword` },
  { account_name: `Chromatography Research Supplies` },
  { account_name: `Chroma Technology Corp.` },
  { account_name: `Chromak Research` },
  { account_name: `Chris Hillseth Enterprises` },
  { account_name: `Chondrex` },
  { account_name: `ChiroSolve` },
  { account_name: `Chimera Biotec` },
  { account_name: `Chenomx,` },
  { account_name: `Chemyx` },
  { account_name: `Chemwerth` },
  { account_name: `Chemtos` },
  { account_name: `Chemstations` },
  { account_name: `Chempacific Corporation` },
  { account_name: `Chemometec` },
  { account_name: `Chemogenics Biopharma` },
  { account_name: `Chemdiv` },
  { account_name: `Chase-Logeman` },
  { account_name: `Chartwell Pharmaceuticals` },
  { account_name: `Charnwood Molecular` },
  { account_name: `Charkit Chemical Corporation` },
  { account_name: `Champions Oncology` },
  { account_name: `Ch3 Biosystems` },
  { account_name: `Cfengine` },
  { account_name: `CFD Research Corporation` },
  { account_name: `Ceutical Labs` },
  { account_name: `Teledyne CETAC Technologies` },
  { account_name: `Cerno Bioscience` },
  { account_name: `Cepheid` },
  { account_name: `Central Drug House` },
  { account_name: `Celther Polska` },
  { account_name: `Celprogen` },
  { account_name: `Celonic` },
  { account_name: `Cellvax` },
  { account_name: `Cell Sciences` },
  { account_name: `Cellomics Technology` },
  { account_name: `Cellix` },
  { account_name: `CELLINK` },
  { account_name: `Sartorius Cellgenix` },
  { account_name: `Cellexus` },
  { account_name: `Cellectricon` },
  { account_name: `Cellecta` },
  { account_name: `Cell Biolabs` },
  { account_name: `Cell Applications` },
  { account_name: `Celerion` },
  { account_name: `CDS Analytical` },
  { account_name: `Cbset` },
  { account_name: `Case Laboratories` },
  { account_name: `Caron Products` },
  { account_name: `Carolina Liquid Chemistries Corp.` },
  { account_name: `Carna Biosciences` },
  { account_name: `Caris Life Sciences` },
  { account_name: `Caretium Medical Instruments` },
  { account_name: `Cardinal Health` },
  { account_name: `Carbolite` },
  { account_name: `Captisol, a Ligand technology` },
  { account_name: `CapsCanada` },
  { account_name: `Capplus Technologies` },
  { account_name: `Capmatic Ltée` },
  { account_name: `CapEval Pharma` },
  { account_name: `Cambridge Isotope Laboratories` },
  { account_name: `Cambridge Intelligence` },
  { account_name: `Cambridge Epigenetix` },
  { account_name: `Cambridge Consultants` },
  { account_name: `Cambrex` },
  { account_name: `CAMAG` },
  { account_name: `Calixar` },
  { account_name: `Calbiotech` },
  { account_name: `Cabka` },
  { account_name: `C. Gerhardt GmbH & Co. KG` },
  { account_name: `Burleson Research Technologies` },
  { account_name: `Burkhead DeVane, Pharmaceutical Label Specialists` },
  { account_name: `Bulldog Bio` },
  { account_name: `Bullard` },
  { account_name: `Buffalo Air Handling` },
  { account_name: `Budzar Industries` },
  { account_name: `Buckman Laboratories International` },
  { account_name: `Brooks Instrument` },
  { account_name: `Brookhaven Instruments` },
  { account_name: `Brook Anco` },
  { account_name: `BroadPharm` },
  { account_name: `BrightSpec` },
  { account_name: `Brentwood` },
  { account_name: `Brenntag` },
  { account_name: `Brendan Technologies` },
  { account_name: `BRANDTECH® Scientific` },
  { account_name: `Brainsway` },
  { account_name: `Brain Products` },
  { account_name: `Brady Corporation` },
  { account_name: `Bracco Imaging` },
  { account_name: `BPS Bioscience` },
  { account_name: `Boulder Scientific Company` },
  { account_name: `Boston Heart Diagnostics` },
  { account_name: `Boston Clinical Trials` },
  { account_name: `Boston Bioproducts` },
  { account_name: `Boston Analytical` },
  { account_name: `Boron Molecular` },
  { account_name: `Borealis` },
  { account_name: `BMT USA` },
  { account_name: `BLUECHIIP LIMITED` },
  { account_name: `Block Engineering` },
  { account_name: `Black Forest Container Systems` },
  { account_name: `Bishop-Wisecarver` },
  { account_name: `Bishamon Industries Corporation` },
  { account_name: `Bischoff Chromatography` },
  { account_name: `Biovista` },
  { account_name: `BioView` },
  { account_name: `BioVendor Research & Diagnostic Products` },
  { account_name: `BioVectra` },
  { account_name: `Biotx Automation` },
  { account_name: `Biotrial` },
  { account_name: `Biotrend Chemicals` },
  { account_name: `Biotix` },
  { account_name: `Biotium` },
  { account_name: `Biotech Support Group` },
  { account_name: `Biotechlogic` },
  { account_name: `BioTeam` },
  { account_name: `Biostat Consultants` },
  { account_name: `Biospherix` },
  { account_name: `BioSolveIt` },
  { account_name: `Biosistemika` },
  { account_name: `BioServe - A REPROCELL Company` },
  { account_name: `Bioseeker Group` },
  { account_name: `BioRASI` },
  { account_name: `Biopticon Corporation` },
  { account_name: `Bioptechs` },
  { account_name: `BioPredic International` },
  { account_name: `BioPioneer` },
  { account_name: `Biophoretics` },
  { account_name: `BIOPAC Systems` },
  { account_name: `Bionivid` },
  { account_name: `Bionique Testing Laboratories` },
  { account_name: `Bionex Solutions` },
  { account_name: `Bio::Neos` },
  { account_name: `Bioneer` },
  { account_name: `Bionano Genomics` },
  { account_name: `Biomodels` },
  { account_name: `bioMérieux` },
  { account_name: `BioMedal` },
  { account_name: `Biomay AG` },
  { account_name: `Biomax Informatics` },
  { account_name: `Biomarker Strategies` },
  { account_name: `Biomapas` },
  { account_name: `Biologics Consulting` },
  { account_name: `Biolin Scientific` },
  { account_name: `Biolink Life Sciences` },
  { account_name: `Biolife Solutions` },
  { account_name: `Biolegio` },
  { account_name: `BioLegend` },
  { account_name: `Bioinvenu Corporation` },
  { account_name: `Bioinformatics Solutions` },
  { account_name: `Bio Image Systems` },
  { account_name: `Biognosys` },
  { account_name: `BioGenex Laboratories` },
  { account_name: `BioGenes` },
  { account_name: `Biofresh` },
  { account_name: `BioForce Nanosciences` },
  { account_name: `Biofficient` },
  { account_name: `BioDuro-Sundia` },
  { account_name: `BioDot` },
  { account_name: `Biodiscovery` },
  { account_name: `BioDatomics` },
  { account_name: `BIOCRATES Life Sciences` },
  { account_name: `BioConnexx` },
  { account_name: `Biochromato` },
  { account_name: `Biochrom` },
  { account_name: `Biocartis` },
  { account_name: `Biocair` },
  { account_name: `Biobridges` },
  { account_name: `Bio Basic` },
  { account_name: `Biobam Bioinformatics` },
  { account_name: `Bioaster` },
  { account_name: `Bioassay Systems` },
  { account_name: `Bioalma` },
  { account_name: `BINDER GmbH` },
  { account_name: `Bilcare` },
  { account_name: `Biaffin` },
  { account_name: `Bertin Technologies` },
  { account_name: `Berthold Technologies` },
  { account_name: `Berkshire Corporation` },
  { account_name: `Benitec Biopharma` },
  { account_name: `Benchworks` },
  { account_name: `Benchmark Products` },
  { account_name: `Benchmark` },
  { account_name: `Benchling` },
  { account_name: `BeMicron` },
  { account_name: `Bellen Chemistry Co` },
  { account_name: `Bellco Glass` },
  { account_name: `BellBrook Labs` },
  { account_name: `BEL Engineering` },
  { account_name: `Behringer Corporation` },
  { account_name: `Behavioral Instruments` },
  { account_name: `Bedcolab` },
  { account_name: `Bc Technical` },
  { account_name: `BCN PEPTIDES` },
  { account_name: `BAUSCH Advanced Technology Group` },
  { account_name: `Batavia Biosciences` },
  { account_name: `Base Pair Biotechnologies` },
  { account_name: `Cerba Research` },
  { account_name: `Bangs Laboratories` },
  { account_name: `Bandelin` },
  { account_name: `Bal Seal Engineering` },
  { account_name: `Balchem Corporation` },
  { account_name: `Baccinex` },
  { account_name: `B&W Tek` },
  { account_name: `Azzur Group` },
  { account_name: `Azumio` },
  { account_name: `AZIDUS LABORATORIES LTD` },
  { account_name: `Ayogo Health` },
  { account_name: `AXXORA` },
  { account_name: `axxin` },
  { account_name: `Axxam` },
  { account_name: `Axol Bioscience` },
  { account_name: `Axis Clinical Software` },
  { account_name: `Axic` },
  { account_name: `Avidin` },
  { account_name: `Avid Bioservices` },
  { account_name: `Avery Dennison` },
  { account_name: `Avantor` },
  { account_name: `Avantium Technologies` },
  { account_name: `Avanti Polar Lipids` },
  { account_name: `Avantgen` },
  { account_name: `Avanade` },
  { account_name: `Avaden Biosciences` },
  { account_name: `Avacta` },
  { account_name: `Ava Biomedical` },
  { account_name: `Automate Scientific` },
  { account_name: `Austin Chemical Company` },
  { account_name: `Aurora Fine Chemicals` },
  { account_name: `Aurora BioScience` },
  { account_name: `Aureus Research Consultants` },
  { account_name: `August Research` },
  { account_name: `A-Tune Software` },
  { account_name: `Atrium Staffing Services.` },
  { account_name: `Atlas5D` },
  { account_name: `Atlantic Analytical Laboratory` },
  { account_name: `Atlanpole Biotherapies` },
  { account_name: `Athena Enzyme Systems` },
  { account_name: `ATGCell` },
  { account_name: `Asynt` },
  { account_name: `Nordson Electronics Solutions` },
  { account_name: `Asymchem` },
  { account_name: `Astro Pak Corporation` },
  { account_name: `Astrix` },
  { account_name: `Astatech` },
  { account_name: `AssayNet` },
  { account_name: `Aspen Technology` },
  { account_name: `Aspect Biosystems` },
  { account_name: `Aspect Automation` },
  { account_name: `Applied Scientific Instrumentation (ASI)` },
  { account_name: `Ashton Tweed` },
  { account_name: `Ashcroft` },
  { account_name: `Aseptic Technologies` },
  { account_name: `Asecos` },
  { account_name: `Arxspan, a Bruker Company` },
  { account_name: `Arup Laboratories` },
  { account_name: `Arryx` },
  { account_name: `Arraystar` },
  { account_name: `Arrayjet` },
  { account_name: `Ariana Pharma` },
  { account_name: `Arcticzymes` },
  { account_name: `Archi-Tech Systems` },
  { account_name: `Archimica` },
  { account_name: `ArcherDX` },
  { account_name: `Aquaneering` },
  { account_name: `Aptochem` },
  { account_name: `Apprentice.io` },
  { account_name: `Getinge` },
  { account_name: `Hudson Surface Technology` },
  { account_name: `Applied Spectral Imaging` },
  { account_name: `Applied Separations` },
  { account_name: `Applied Maths` },
  { account_name: `Applied Cytometry` },
  { account_name: `Applied Chemical Technology` },
  { account_name: `Applied Biological Materials` },
  { account_name: `Applied Biocode` },
  { account_name: `Applied Automation Robotics` },
  { account_name: `Appistry` },
  { account_name: `Apollo Scientific` },
  { account_name: `Apogee Instruments` },
  { account_name: `Apicore` },
  { account_name: `APIcells` },
  { account_name: `Apica` },
  { account_name: `Aphena Pharma Solutions Holdings` },
  { account_name: `Apeiron Synthesis` },
  { account_name: `Antigenix America` },
  { account_name: `Antibody Resource Page` },
  { account_name: `Antibody Research Corp` },
  { account_name: `Antibodies online .com` },
  { account_name: `Antibodies` },
  { account_name: `Antabio` },
  { account_name: `Ansys` },
  { account_name: `Anritsu` },
  { account_name: `Aniara Diagnostica` },
  { account_name: `Andreas Hettich` },
  { account_name: `Andor Technology` },
  { account_name: `Anaxomics Biotech` },
  { account_name: `Anasazi Instruments` },
  { account_name: `Analyze IQ` },
  { account_name: `Analytik Jena` },
  { account_name: `ASAP Analytical` },
  { account_name: `Analiza` },
  { account_name: `ANALAB` },
  { account_name: `AM Technology` },
  { account_name: `A-M Systems` },
  { account_name: `AMS Filling Systems` },
  { account_name: `AMSBIO` },
  { account_name: `Amphora Research Systems` },
  { account_name: `Ampac Fine Chemicals` },
  { account_name: `Ametek` },
  { account_name: `Ameri-Seal` },
  { account_name: `American Packaging Corp.` },
  { account_name: `Amcor` },
  { account_name: `Ambry Genetics` },
  { account_name: `AmbioPharm` },
  { account_name: `Alzchem` },
  { account_name: `Alturas Analytics` },
  { account_name: `Altoris` },
  { account_name: `Alteryx` },
  { account_name: `Alternative Design Manufacturing and Supply` },
  { account_name: `Altasciences` },
  { account_name: `Alpha Resources` },
  { account_name: `Alpha Omega` },
  { account_name: `Alphalyse` },
  { account_name: `Alpha Genesis` },
  { account_name: `Alomone Labs` },
  { account_name: `Almax easyLab` },
  { account_name: `Allscripts Healthcare Solutions` },
  { account_name: `Allphase Clinical Research` },
  { account_name: `Allos` },
  { account_name: `Alliance Pharma` },
  { account_name: `Allentown` },
  { account_name: `Allele Biotechnology & Pharmaceuticals` },
  { account_name: `Allegheny Bradford Corp` },
  { account_name: `AllCells` },
  { account_name: `ALine` },
  { account_name: `Alert Scientific` },
  { account_name: `Alcor Scientific` },
  { account_name: `Alconox` },
  { account_name: `Albemarle Corporation` },
  { account_name: `ALA Scientific Instruments` },
  { account_name: `Akron Bio` },
  { account_name: `Akonni Biosystems` },
  { account_name: `Ajinomoto Bio-Pharma Services` },
  { account_name: `Aiv Solutions` },
  { account_name: `Air Products and Chemicals` },
  { account_name: `Air Process Systems & Conveyors` },
  { account_name: `Airgas` },
  { account_name: `AG Scientific` },
  { account_name: `Agrivida` },
  { account_name: `Judi by AG Mednet` },
  { account_name: `AgileBio` },
  { account_name: `Affinity Biosensors` },
  { account_name: `Aerotek` },
  { account_name: `Aerotech` },
  { account_name: `Aerofil Technology` },
  { account_name: `Advent Design Corporation` },
  { account_name: `Advance Scientific & Chemical` },
  { account_name: `Advance Research Chemicals` },
  { account_name: `Advanced Targeting Systems` },
  { account_name: `Advanced Solutions Life Sciences` },
  { account_name: `Advanced Poly-Packaging` },
  { account_name: `ANT Neuro` },
  { account_name: `Advanced Molding Technologies` },
  { account_name: `MDI Membrane Technologies` },
  { account_name: `Advanced Instruments` },
  { account_name: `Advanced Imaging Concepts` },
  { account_name: `Advanced Concept Innovations` },
  { account_name: `Advanced Chemtech` },
  { account_name: `Advanced ChemBlocks Inc (AChemBlock)` },
  { account_name: `Advanced Brain Monitoring` },
  { account_name: `Advanced Biotechnologies` },
  { account_name: `Advanced Biomatrix` },
  { account_name: `Advaita Bioinformatics` },
  { account_name: `Adron Systems` },
  { account_name: `Adpen Laboratories` },
  { account_name: `AdipoGen Life Sciences` },
  { account_name: `Adinstruments` },
  { account_name: `Adecco` },
  { account_name: `Addgene` },
  { account_name: `ADCO Manufacturing` },
  { account_name: `Acunetix by Invicti` },
  { account_name: `Acumenta` },
  { account_name: `Actus Data` },
  { account_name: `ActiGraph` },
  { account_name: `Actian` },
  { account_name: `Acsis` },
  { account_name: `Across Systems` },
  { account_name: `Acrobiosystems` },
  { account_name: `Acquia` },
  { account_name: `ACM Medical Laboratory` },
  { account_name: `Aclara` },
  { account_name: `ACI Infotech` },
  { account_name: `ACGT` },
  { account_name: `Ace Glass` },
  { account_name: `Advanced Chromatography Technologies` },
  { account_name: `Accutek Packaging Equipment` },
  { account_name: `Accurion` },
  { account_name: `Accupac` },
  { account_name: `Accunet Solutions` },
  { account_name: `Accugenomics` },
  { account_name: `Accraply` },
  { account_name: `Accium Biosciences` },
  { account_name: `Acceleration Laboratory Services` },
  { account_name: `Accelera - Part of NMS Group` },
  { account_name: `AcceleDev` },
  { account_name: `Accedian` },
  { account_name: `Acasi Machinery` },
  { account_name: `Acanthus Research` },
  { account_name: `Absolute Software` },
  { account_name: `Abox Automation Corp.` },
  { account_name: `Abnova` },
  { account_name: `Abitec Corporation` },
  { account_name: `ABF Pharmaceutical Services` },
  { account_name: `Aber Instruments` },
  { account_name: `ABEC` },
  { account_name: `AB Controls` },
  { account_name: `Eurofins BioPharma Product Testing` },
  { account_name: `AbCheck` },
  { account_name: `AbCellera` },
  { account_name: `Abbiotec` },
  { account_name: `AAT Bioquest` },
  { account_name: `AAPharmaSyn` },
  { account_name: `Aapptec` },
  { account_name: `Aalto Scientific` },
  { account_name: `A10 Clinical Solutions` },
  { account_name: `A&D Weighing` },
  { account_name: `908 Devices` },
  { account_name: `89 North` },
  { account_name: `5am Solutions` },
  { account_name: `3D Systems` },
  { account_name: `3D Biotek` },
  { account_name: `2nd Watch` },
  { account_name: `23andme` },
  { account_name: `10x Genomics` },
  { account_name: `1010data` },
  { account_name: `Quadmark` },
  { account_name: `Q-State Biosciences` },
  { account_name: `Qosina` },
  { account_name: `Qlucore` },
  { account_name: `Qlik` },
  { account_name: `Q Laboratories` },
  { account_name: `Quantum Analytics` },
  { account_name: `Pyxant Labs` },
  { account_name: `Pyramid Laboratories` },
  { account_name: `Purolite Healthcare & Life Sciences` },
  { account_name: `Puritan Medical Products` },
  { account_name: `Puresyn` },
  { account_name: `Puracyp` },
  { account_name: `PsychoGenics` },
  { account_name: `Proveris Scientific` },
  { account_name: `Protrials Research` },
  { account_name: `Proto Labs` },
  { account_name: `Protoform` },
  { account_name: `Proteros Biostructures` },
  { account_name: `Proteome Software` },
  { account_name: `Proteome Sciences` },
  { account_name: `ProteoGenix` },
  { account_name: `ProteinOne` },
  { account_name: `Protein Lounge` },
  { account_name: `PROSYNTH` },
  { account_name: `Prosci` },
  { account_name: `ProPharma` },
  { account_name: `Propagenix` },
  { account_name: `Prometheus Research, an IQVIA business` },
  { account_name: `ProImmune` },
  { account_name: `Progenika` },
  { account_name: `Proditec` },
  { account_name: `Proconex` },
  { account_name: `Process NMR Associates` },
  { account_name: `ProBioGen` },
  { account_name: `Prior Scientific` },
  { account_name: `Princeton Separations` },
  { account_name: `Princeton Chromatography` },
  { account_name: `Primera Analytical Solutions Corporation` },
  { account_name: `Prevalent` },
  { account_name: `Pressure Biosciences` },
  { account_name: `PreSens` },
  { account_name: `Presage Biosciences` },
  { account_name: `Premier Lab Supply` },
  { account_name: `PREMIER Biosoft` },
  { account_name: `Premas Biotech` },
  { account_name: `Yourgene Health` },
  { account_name: `Predictive Biology` },
  { account_name: `Preclin Biosystems AG` },
  { account_name: `Precision Stability Storage` },
  { account_name: `Precisionmed` },
  { account_name: `Precision Biosciences` },
  { account_name: `Precisionary Instruments` },
  { account_name: `PreAnalytiX` },
  { account_name: `PRA Health Sciences` },
  { account_name: `Clarivate` },
  { account_name: `Adhesives Research` },
  { account_name: `Twist Bioscience` },
  { account_name: `Selvita` },
  { account_name: `Model N` },
  { account_name: `TeraView` },
  { account_name: `AMETEK Reichert` },
  { account_name: `Edge BioSystems` },
  { account_name: `Biostatus` },
  { account_name: `BioMedTech Laboratories` },
  { account_name: `American Radiolabeled Chemicals` },
  { account_name: `Sage N Research` },
  { account_name: `Ncardia` },
  { account_name: `Axis Communications` },
  { account_name: `Axway` },
  { account_name: `YMC America, Process Technologies Group` },
  { account_name: `Worthington Biochemical` },
  { account_name: `Worldwide Glass Resources` },
  { account_name: `Vodafone` },
  { account_name: `Trace3` },
  { account_name: `Thomson Instrument Company` },
  { account_name: `TEKnova` },
  { account_name: `ION Biosciences` },
  { account_name: `Stirling Ultracold` },
  { account_name: `Specs - Compound Handling BV` },
  { account_name: `Smithers` },
  { account_name: `SimSpace Corporation` },
  { account_name: `Skai` },
  { account_name: `Shenandoah Biotechnology` },
  { account_name: `SQream Technologies` },
  { account_name: `SP Scientific` },
  { account_name: `Q² Solutions` },
  { account_name: `Quadras` },
  { account_name: `Puppet` },
  { account_name: `Progressive Medical Research` },
  { account_name: `Plas-Labs` },
  { account_name: `PharmaCadence Analytical Services` },
  { account_name: `Pfanstiehl` },
  { account_name: `Parsec Automation` },
  { account_name: `Nicos Group` },
  { account_name: `wcg IRB` },
  { account_name: `NeoGenomics` },
  { account_name: `Nagase America` },
  { account_name: `Molecular Cloning Laboratories (MCLAB)` },
  { account_name: `MeCour Temperature Control` },
  { account_name: `Material Handling Systems` },
  { account_name: `MS Bioworks` },
  { account_name: `Crown Castle` },
  { account_name: `LabKey` },
  { account_name: `Kinexus Bioinformatics` },
  { account_name: `Kemp Proteins` },
  { account_name: `Japan Bioinformatics KK` },
  { account_name: `JPT Peptide Technologies` },
  { account_name: `Intellitech Technologies` },
  { account_name: `Infosys` },
  { account_name: `INDIGO Biosciences` },
  { account_name: `Immunochemistry Technologies` },
  { account_name: `ITOCHU Chemicals America` },
  { account_name: `IKA` },
  { account_name: `Greenphire` },
  { account_name: `Futran Solutions` },
  { account_name: `Porsolt` },
  { account_name: `Flowmetric` },
  { account_name: `Experis` },
  { account_name: `Eliassen Group` },
  { account_name: `Clario` },
  { account_name: `Dx Biosamples` },
  { account_name: `Data Sciences International (DSI)` },
  { account_name: `Columbia Biosciences` },
  { account_name: `Orion Innovation` },
  { account_name: `CEDARLANE` },
  { account_name: `Blue Heron Biotech` },
  { account_name: `Black Dog Technical Services` },
  { account_name: `Bio-Techne` },
  { account_name: `Bio-Synthesis` },
  { account_name: `BGI Genomics` },
  { account_name: `ArcticWhite` },
  { account_name: `Arbill` },
  { account_name: `Ancillare` },
  { account_name: `Albumedix` },
  { account_name: `AkzoNobel` },
  { account_name: `Active Power` },
  { account_name: `Accelagen` },
  { account_name: `Associates of Cape Cod` },
  { account_name: `Atlas` },
  { account_name: `Microsoft Avere Systems` },
  { account_name: `Envigo ++++` },
  { account_name: `Alternative Biomedical Solutions` },
  { account_name: `ingenious targeting laboratory` },
  { account_name: `Oracle goBalto` },
  { account_name: `deltaDOT` },
  { account_name: `Zymo Research` },
  { account_name: `ZenBio` },
  { account_name: `CXV Global` },
  { account_name: `Wyatt Technology` },
  { account_name: `WuXi AppTec` },
  { account_name: `Weiler Engineering` },
  { account_name: `Waters` },
  { account_name: `W. L. Gore & Associates` },
  { account_name: `FUJIFILM VisualSonics` },
  { account_name: `Philips Pharma Solutions` },
  { account_name: `Ventana Medical Systems` },
  { account_name: `Veltek Associates` },
  { account_name: `Veeva Systems` },
  { account_name: `Veeco Instruments` },
  { account_name: `VWR, Part of Avantor` },
  { account_name: `VMware` },
  { account_name: `Univa` },
  { account_name: `Two Square Science` },
  { account_name: `Tripwire` },
  { account_name: `TriLink BioTechnologies, part of Maravai LifeSciences` },
  { account_name: `TriFoil Imaging` },
  { account_name: `Transposagen Biopharmaceuticals` },
  { account_name: `Precipio` },
  { account_name: `Tom Sawyer Software` },
  { account_name: `Tocris Bioscience` },
  { account_name: `Titian Software` },
  { account_name: `Thomson Reuters` },
  { account_name: `Thermo Fisher Scientific` },
  { account_name: `MathWorks` },
  { account_name: `The Jackson Laboratory` },
  { account_name: `ThalesNano` },
  { account_name: `Texture Technologies` },
  { account_name: `Texcell - North America` },
  { account_name: `Testo Instruments - North America` },
  { account_name: `Teradata` },
  { account_name: `Tepnel Pharma Services` },
  { account_name: `Tecan` },
  { account_name: `Tata Consultancy Services` },
  { account_name: `Take Solutions` },
  { account_name: `Taconic Biosciences` },
  { account_name: `SPT Labtech` },
  { account_name: `TIBCO Software` },
  { account_name: `TEKsystems` },
  { account_name: `Waters (TA Instruments)` },
  { account_name: `Syngene International` },
  { account_name: `Syncom` },
  { account_name: `StemCells` },
  { account_name: `STEMCELL Technologies` },
  { account_name: `Splunk` },
  { account_name: `Spectrum Chemical` },
  { account_name: `SomaLogic` },
  { account_name: `Sinclair Research` },
  { account_name: `Simulations Plus` },
  { account_name: `Resonac` },
  { account_name: `ServiceNow` },
  { account_name: `SeraCare` },
  { account_name: `Sentinel Process Systems` },
  { account_name: `Senova Systems` },
  { account_name: `Scientist.com` },
  { account_name: `Schrödinger` },
  { account_name: `Sartorius Stedim Biotech` },
  { account_name: `SaniSure` },
  { account_name: `Salesforce` },
  { account_name: `Saint-Gobain` },
  { account_name: `Shimadzu Scientific Instruments` },
  { account_name: `SGI` },
  { account_name: `SDIX, an Origene Company` },
  { account_name: `SCIEX` },
  { account_name: `SB Drug Discovery` },
  { account_name: `SAS` },
  { account_name: `SAP` },
  { account_name: `SAI Infusion Technologies` },
  { account_name: `S-Matrix` },
  { account_name: `Rudolph Research Analytical` },
  { account_name: `Rommelag` },
  { account_name: `Roche Diagnostics` },
  { account_name: `Riverbed Technology` },
  { account_name: `Olon Ricerca Bioscience` },
  { account_name: `Rho` },
  { account_name: `Restek` },
  { account_name: `Resodyn Acoustic Mixers` },
  { account_name: `Repligen` },
  { account_name: `Refine Technology` },
  { account_name: `Red Hat` },
  { account_name: `Reaction Biology` },
  { account_name: `Randox` },
  { account_name: `Rainbow Scientific` },
  { account_name: `Radix BioSolutions` },
  { account_name: `Rackspace Technology` },
  { account_name: `Quest Diagnostics` },
  { account_name: `Quantachrome Instruments` },
  { account_name: `QPS` },
  { account_name: `QIAGEN` },
  { account_name: `Proteos` },
  { account_name: `ProteoGenex` },
  { account_name: `Proteintech` },
  { account_name: `ProteinSimple` },
  { account_name: `PromoCell` },
  { account_name: `Promega` },
  { account_name: `Price Waterhouse Coopers-PwC` },
  { account_name: `Prestwick Chemical Libraries` },
  { account_name: `Precision Antibody` },
  { account_name: `Praxair` },
  { account_name: `PSL (Powder Systems Limited)` },
  { account_name: `Pneumatic Scale Angelus` },
  { account_name: `Phenomenex` },
  { account_name: `Pelican Expression Technology` },
  { account_name: `PerkinElmer` },
  { account_name: `PeproTech` },
  { account_name: `PendoTECH` },
  { account_name: `Pegasystems` },
  { account_name: `Partek` },
  { account_name: `Parker Bioscience` },
  { account_name: `CGI` },
  { account_name: `PHC Corporation of North America` },
  { account_name: `Pall Corporation` },
  { account_name: `Palisade` },
  { account_name: `Nexelis, a Q² Solutions Company` },
  { account_name: `Pace Life Sciences` },
  { account_name: `PTS Data Center Solutions` },
  { account_name: `PPD` },
  { account_name: `PBS Biotech` },
  { account_name: `PBL Assay Science` },
  { account_name: `Parexel` },
  { account_name: `OriGene Technologies` },
  { account_name: `Oracle` },
  { account_name: `Optum` },
  { account_name: `OPTIMA Pharma` },
  { account_name: `Onyx Scientific` },
  { account_name: `Oncodesign Services` },
  { account_name: `OSIsoft` },
  { account_name: `Novoprotein Scientific` },
  { account_name: `Nova Biomedical` },
  { account_name: `Nordson Medical` },
  { account_name: `Nordion a Sotera Health Company` },
  { account_name: `Nikon Instruments` },
  { account_name: `NextBio` },
  { account_name: `Nexcelom Bioscience from PerkinElmer` },
  { account_name: `New Relic` },
  { account_name: `Biosynth` },
  { account_name: `New England Biolabs` },
  { account_name: `Netscout Systems` },
  { account_name: `Neta Scientific` },
  { account_name: `NetApp` },
  { account_name: `NanoString Technologies` },
  { account_name: `NanoImaging Services` },
  { account_name: `NTT Data` },
  { account_name: `NNIT` },
  { account_name: `Molecular Devices` },
  { account_name: `Mirus Bio` },
  { account_name: `Mini Graphics` },
  { account_name: `Mimotopes` },
  { account_name: `Miltenyi Biotec` },
  { account_name: `MilliporeSigma` },
  { account_name: `Millennium Research Laboratories` },
  { account_name: `Miele` },
  { account_name: `Microway` },
  { account_name: `Microtrac` },
  { account_name: `Microsoft` },
  { account_name: `Micromeritics Instrument` },
  { account_name: `Microfluidics International Corporation` },
  { account_name: `Microbial IDentification (MIDI)` },
  { account_name: `Mettler Toledo Rainin` },
  { account_name: `Metrohm USA` },
  { account_name: `Metrics Contract Services` },
  { account_name: `Metabolon` },
  { account_name: `Mercodia` },
  { account_name: `Meissner Filtration Products` },
  { account_name: `Megaputer Intelligence` },
  { account_name: `Medidata Solutions` },
  { account_name: `McAfee` },
  { account_name: `Bureau Veritas North America` },
  { account_name: `MaxisIT` },
  { account_name: `Masy BioServices, an Alcami company` },
  { account_name: `Malvern Panalytical` },
  { account_name: `MakroCare` },
  { account_name: `MVA Scientific Consultants` },
  { account_name: `MTIBio` },
  { account_name: `MKS Instruments` },
  { account_name: `MACHEREY-NAGEL` },
  { account_name: `MAC-MOD Analytical` },
  { account_name: `Luminex Corporation - A DiaSorin Company` },
  { account_name: `Lonza` },
  { account_name: `Linguamatics` },
  { account_name: `Lenovo` },
  { account_name: `Leica Biosystems` },
  { account_name: `Labcyte` },
  { account_name: `LabWare` },
  { account_name: `LI-COR Biotechnology` },
  { account_name: `Kymanox` },
  { account_name: `Kofax` },
  { account_name: `Keyence` },
  { account_name: `Endress+Hauser Kaiser Optical Systems` },
  { account_name: `KPMG` },
  { account_name: `KD Scientific` },
  { account_name: `KBI Biopharma` },
  { account_name: `Julabo` },
  { account_name: `Johnson Matthey` },
  { account_name: `Jasco` },
  { account_name: `JSR Life Sciences` },
  { account_name: `Viavi Solutions` },
  { account_name: `IonField Systems` },
  { account_name: `Syneos Health` },
  { account_name: `IntraLinks` },
  { account_name: `Intertek` },
  { account_name: `Intelligent Imaging Innovations (3i)` },
  { account_name: `Intel` },
  { account_name: `Integrated DNA Technologies` },
  { account_name: `Instech Laboratories` },
  { account_name: `Informatica` },
  { account_name: `Indivumed` },
  { account_name: `InSphero` },
  { account_name: `Imtakt USA` },
  { account_name: `Illumina` },
  { account_name: `INTEGRA Biosciences` },
  { account_name: `IDEX Health & Science` },
  { account_name: `IDBS` },
  { account_name: `ICONICS` },
  { account_name: `ICON` },
  { account_name: `IBM` },
  { account_name: `IBI Scientific` },
  { account_name: `Hydro Service` },
  { account_name: `Human Metabolome Technologies` },
  { account_name: `Hortonworks` },
  { account_name: `Horizon Discovery` },
  { account_name: `Horiba Scientific` },
  { account_name: `Hitachi High Technologies America` },
  { account_name: `Hitachi Vantara` },
  { account_name: `Hirox USA` },
  { account_name: `HighRes Biosolutions` },
  { account_name: `Hewlett Packard Enterprise` },
  { account_name: `Healarium` },
  { account_name: `Teledyne Hanson` },
  { account_name: `Hamilton Company` },
  { account_name: `Hamamatsu` },
  { account_name: `HCL America` },
  { account_name: `Gyros Protein Technologies` },
  { account_name: `Groninger USA` },
  { account_name: `W. R. Grace & Co.` },
  { account_name: `Google` },
  { account_name: `Global Clinical Trials (GCT)` },
  { account_name: `Glen Mills` },
  { account_name: `Gilson` },
  { account_name: `Gigamon` },
  { account_name: `Genpact` },
  { account_name: `Genoptix, a NeoGenomics Company` },
  { account_name: `Genisphere` },
  { account_name: `Genedata` },
  { account_name: `GenScript` },
  { account_name: `Gelest` },
  { account_name: `GENEWIZ from Azenta Life Sciences` },
  { account_name: `Cytiva` },
  { account_name: `Frontier Scientific` },
  { account_name: `Formulatrix` },
  { account_name: `Discovery Life Sciences` },
  { account_name: `Standard Biotools` },
  { account_name: `FlackTek SpeedMixer` },
  { account_name: `F5` },
  { account_name: `Exostar Life Sciences` },
  { account_name: `Evotec` },
  { account_name: `Eurofins Lancaster Laboratories` },
  { account_name: `Eppendorf` },
  { account_name: `Enzo Life Sciences` },
  { account_name: `Entegris` },
  { account_name: `Vertiv` },
  { account_name: `Elsevier` },
  { account_name: `Cloudbees` },
  { account_name: `EY` },
  { account_name: `Dualsystems Biotech` },
  { account_name: `Dow` },
  { account_name: `Dotmatics` },
  { account_name: `Distek` },
  { account_name: `Eurofins DiscoverX` },
  { account_name: `DeltaSoft` },
  { account_name: `Deloitte` },
  { account_name: `De Novo Software` },
  { account_name: `DataForm Software` },
  { account_name: `Dassault Systèmes` },
  { account_name: `DSM` },
  { account_name: `DNASTAR` },
  { account_name: `ATUM` },
  { account_name: `Cyprotex` },
  { account_name: `Microsoft Cycle Computing` },
  { account_name: `Curiox Biosystems` },
  { account_name: `Cureline Group - Global Translational CRO` },
  { account_name: `Crown BioScience` },
  { account_name: `Covaris` },
  { account_name: `Labcorp Drug Development` },
  { account_name: `Consumer Product Testing Company` },
  { account_name: `Computype` },
  { account_name: `DXC Technology` },
  { account_name: `Commvault` },
  { account_name: `Collabera` },
  { account_name: `Cognizant` },
  { account_name: `Codexis` },
  { account_name: `Cloudera` },
  { account_name: `Clinilabs` },
  { account_name: `Clinical Ink` },
  { account_name: `Clemex Technologies` },
  { account_name: `Citrix` },
  { account_name: `Cisco` },
  { account_name: `Chromatan` },
  { account_name: `Chemspeed Technologies` },
  { account_name: `Chemical Computing Group` },
  { account_name: `Chemglass Life Sciences` },
  { account_name: `ChemImage` },
  { account_name: `ChemGenes` },
  { account_name: `ChemBridge` },
  { account_name: `ChemAxon` },
  { account_name: `Charter Medical` },
  { account_name: `Charles River Laboratories` },
  { account_name: `Certara` },
  { account_name: `Cerilliant - a Millipore Sigma Company` },
  { account_name: `Ceres Nanosciences` },
  { account_name: `CerCell` },
  { account_name: `FUJIFILM Cellular Dynamics` },
  { account_name: `Cell Signaling Technology (CST)` },
  { account_name: `Cayman Chemical` },
  { account_name: `Caudex | An IPG Health Company` },
  { account_name: `Catalent Pharma Solutions` },
  { account_name: `ZEISS Microscopy` },
  { account_name: `Capsugel` },
  { account_name: `CellCarta` },
  { account_name: `Capital Biosciences` },
  { account_name: `Cambridge Semantics` },
  { account_name: `Cambridge Research Biochemicals (CRB)` },
  { account_name: `CPC` },
  { account_name: `COSA Xentaur` },
  { account_name: `CIRION BioPharma Research` },
  { account_name: `CIMA Labs` },
  { account_name: `CEVEC Pharmaceuticals - now part of Cytiva` },
  { account_name: `CEM Corporation` },
  { account_name: `CARBOGEN AMCIS` },
  { account_name: `CACTUS Communications` },
  { account_name: `C3i Solutions` },
  { account_name: `Buck Scientific` },
  { account_name: `Bruker` },
  { account_name: `Azenta Life Sciences` },
  { account_name: `Broadley-James` },
  { account_name: `Brimrose` },
  { account_name: `BrightPlanet` },
  { account_name: `BrainBits` },
  { account_name: `Syntegon` },
  { account_name: `Booz Allen Hamilton` },
  { account_name: `Curia` },
  { account_name: `Biotage` },
  { account_name: `Biosero` },
  { account_name: `LGC Biosearch Technologies` },
  { account_name: `BioReliance` },
  { account_name: `BioIVT` },
  { account_name: `Biological Specialty Company (BSC)` },
  { account_name: `Biolog` },
  { account_name: `Biodesix` },
  { account_name: `Biocare Medical` },
  { account_name: `BioAgilytix` },
  { account_name: `BioTools` },
  { account_name: `Agilent BioTek Instruments` },
  { account_name: `ProterixBio` },
  { account_name: `BioPharma Services` },
  { account_name: `Q2 Solutions (formerly BioFortis)` },
  { account_name: `BioChemed Services` },
  { account_name: `BioChain` },
  { account_name: `Bio-Rad Laboratories` },
  { account_name: `Beckman Coulter Life Sciences` },
  { account_name: `Bausch+Ströbel` },
  { account_name: `Battelle` },
  { account_name: `Infinity Laboratories` },
  { account_name: `Bahnson` },
  { account_name: `Bachem` },
  { account_name: `BTS Research` },
  { account_name: `BRANDEL` },
  { account_name: `BMG LABTECH` },
  { account_name: `BMC Software` },
  { account_name: `BD Biosciences` },
  { account_name: `BASi Research Products` },
  { account_name: `BASF Pharma Solutions` },
  { account_name: `Axion BioSystems` },
  { account_name: `Aviva Systems Biology` },
  { account_name: `Autoscribe Informatics` },
  { account_name: `Autogen` },
  { account_name: `Aurora Biomed` },
  { account_name: `Attana` },
  { account_name: `Asuragen, a bio-techne brand` },
  { account_name: `Asinex` },
  { account_name: `Ashland` },
  { account_name: `Asentech` },
  { account_name: `Asahi Kasei Bioprocess` },
  { account_name: `Arista Networks` },
  { account_name: `ArisGlobal` },
  { account_name: `ArborSys Group` },
  { account_name: `Aragen Life Sciences` },
  { account_name: `Aptar` },
  { account_name: `Aptagen` },
  { account_name: `Applied StemCell` },
  { account_name: `Applied Robotics` },
  { account_name: `Apple` },
  { account_name: `Appian` },
  { account_name: `Antibody Solutions` },
  { account_name: `AnalytiCon Discovery` },
  { account_name: `AnaSpec` },
  { account_name: `Amazon Web Services` },
  { account_name: `Almac Group` },
  { account_name: `Alfa Wassermann` },
  { account_name: `Aldevron` },
  { account_name: `Akamai Technologies` },
  { account_name: `Agilent Technologies` },
  { account_name: `Agena Bioscience` },
  { account_name: `Aetea Information Technology` },
  { account_name: `Aequor Technologies` },
  { account_name: `Advion Interchim Scientific` },
  { account_name: `AdvantaPure/NewAge Industries` },
  { account_name: `Advanced Cell Diagnostics - a Biotechne Brand` },
  { account_name: `Advanced BioScience Laboratories (ABL)` },
  { account_name: `Adobe` },
  { account_name: `Adesis` },
  { account_name: `Active Motif` },
  { account_name: `Accu-Scope` },
  { account_name: `Accenture` },
  { account_name: `Accelerated Technology Laboratories` },
  { account_name: `Accela ChemBio` },
  { account_name: `Absorption Systems, a Pharmaron company` },
  { account_name: `Abcepta` },
  { account_name: `Abcam` },
  { account_name: `ATCC` },
  { account_name: `AT&T` },
  { account_name: `ARVYS Proteins` },
  { account_name: `Artel by Advanced Instruments` },
  { account_name: `ALS Automated Lab Solutions` },
  { account_name: `Advanced Chemistry Development` },
  { account_name: `Analytical Biological Services` },
  { account_name: `Alcami` },
  { account_name: `3DHISTECH` },
  { account_name: `20/20 GeneSystems` },
  { account_name: `Aquasyn` },
  { account_name: `World Wide Technology` },
  { account_name: `Sinequa` },
  { account_name: `Exigo Manufacturing` },
  { account_name: `TSR Consulting Services` },
  { account_name: `insightsoftware` },
  { account_name: `Kneat Solutions` },
  { account_name: `Blackberry` },
  { account_name: `Librestream Technologies` },
  { account_name: `Partner's Consulting` },
  { account_name: `Merrimak Capital Company` },
  { account_name: `Xylem` },
  { account_name: `Reltio` },
  { account_name: `Unchained Labs` },
  { account_name: `Creoptix — a Malvern Panalytical brand` },
  { account_name: `Busch Vacuum Solutions` },
  { account_name: `Biocytogen` },
  { account_name: `Semaphore Solutions` },
  { account_name: `Princeton Blue` },
  { account_name: `Pharmaspectra` },
  { account_name: `SOTAX` },
  { account_name: `The Judge Group` },
  { account_name: `Ontrack` },
  { account_name: `Benchmark Consulting` },
  { account_name: `Genalyte` },
  { account_name: `Intrinsic Imaging` },
  { account_name: `BlueCatBio` },
  { account_name: `Shanghai AQ Biopharma` },
  { account_name: `Blue Ocean Robotics` },
  { account_name: `Iris Software` },
  { account_name: `Brillio` },
  { account_name: `Alpha Clinical Systems` },
  { account_name: `SkillSoft` },
  { account_name: `Highpoint Solutions, An IQVIA Company` },
  { account_name: `Sylogent, an Anju Software Company` },
  { account_name: `Verinext` },
  { account_name: `SQLI` },
  { account_name: `Limeade` },
  { account_name: `High Purity New England` },
  { account_name: `MedInstill` },
  { account_name: `Tamr` },
  { account_name: `MakerBot` },
  { account_name: `Diversant` },
  { account_name: `Apex Systems` },
  { account_name: `Terumo Blood and Cell Technologies` },
  { account_name: `NCS Technologies` },
  { account_name: `Yorktel` },
  { account_name: `ResultWorks - An Astrix Business` },
  { account_name: `Photon` },
  { account_name: `Alpha Consulting` },
  { account_name: `Infragistics` },
  { account_name: `GSTi` },
  { account_name: `CGS Technology Associates` },
  { account_name: `Brainshark` },
  { account_name: `BCT Partners` },
  { account_name: `WhoKnows` },
  { account_name: `PowWowNow` },
  { account_name: `Strateos` },
  { account_name: `Levitronix` },
  { account_name: `Integrated Clinical Systems` },
  { account_name: `Hired by Matrix` },
  { account_name: `TurnKey Solutions` },
  { account_name: `Cimquest` },
  { account_name: `DVL Group` },
  { account_name: `Townsend & Associates` },
  { account_name: `Fortira` },
  { account_name: `Pontoon Solutions` },
  { account_name: `NanoTemper Technologies` },
  { account_name: `Tech Mahindra` },
  { account_name: `BSI` },
  { account_name: `Nutanix` },
  { account_name: `ALLpaQ Packaging` },
  { account_name: `GEMÜ Valves` },
  { account_name: `Connectria Hosting` },
  { account_name: `Software AG` },
  { account_name: `Alfresco` },
  { account_name: `Zscaler` },
  { account_name: `AgileOne` },
  { account_name: `Precision for Medicine` },
  { account_name: `ForeScout Technologies` },
  { account_name: `LIBRA INDUSTRIES` },
  { account_name: `Kelly Communications Systems` },
  { account_name: `Biopharm Services` },
  { account_name: `Lightower Fibertech Networks` },
  { account_name: `Biologics Modular` },
  { account_name: `Open Access Systems` },
  { account_name: `SQI Diagnostics` },
  { account_name: `Essential Pharmaceuticals` },
];

export const jobTitles = [
  'Account Manager',
  'Application Scientist',
  'Associate Director',
  'Associate Principal Scientist',
  'Associate Scientist',
  'Associate Specialist',
  'Associate Vice President (AVP)',
  'Bioengineer',
  'Chief Executive Officer (CEO)',
  'Chief Information Officer (CIO)',
  'Chief Operating Officer (COO)',
  'Chief Technology Officer (CTO)',
  'Contractor',
  'Director',
  'Executive Director',
  'Executive Vice President (EVP)',
  'Field Application Scientist',
  'Head',
  'Intern',
  'Key Account Manager',
  'Lead Associate',
  'Manager',
  'Managing Director',
  'Post Doc Fellow',
  'President',
  'Principal Scientist',
  'Product Manager',
  'Product Specialist',
  'Regional Marketing Manager',
  'Regional Sales Manager',
  'Sales Director',
  'Sales Manager',
  'Sales Representative',
  'Sales Specialist',
  'Scientific Advisor',
  'Scientist',
  'Senior Account Manager',
  'Senior Manager',
  'Senior Principal Scientist',
  'Senior Scientist',
  'Senior Specialist',
  'Sourcing Analyst',
  'Sourcing Manager',
  'Specialist',
  'Strategic Account Director',
  'Team Leader',
  'Technical Account Manager',
  'Vice President',
];

export const keywordsSanofi = [
  'Analytical Symposium',
  'LNP',
  'Synthetics',
  'Biologics',
  'New Technologies',
  'mRNA Modalities',
  'Digital',
  'Vaccines',
  'Overcoming Challenges',
  'Multi',
  'Omic',
  'Analyst',
  'HPLC',
  'KF',
  'Integration',
  'Impurities',
  'LCMS',
  'Dissolution',
  'Bioassay',
  'Degradation',
  'Stability',
  'Validation',
];
