import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import getValidUrl from '../../Utilities/getValidUrl';
import axios from 'axios';
import { eventId } from '../../config';
import { useSelector } from 'react-redux';
import { useGetEventQuery } from '../../Services/queryApi';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function Venue(props) {
  const classes = useStyles();
  const [page, setPage] = useState([]);
  // access redux to get strapi
  const strapiPagesRedux = useSelector((state) => state.strapi);
  const eventInformation = useSelector((state) => state.event);
  // configure menu item

  const { data, error, isLoading } = useGetEventQuery(eventId);
  // let venueAccountId = props?.content?.replace('<p>', '').replace('</p>', '');
  // console.log('venueAccountId:', venueAccountId);

  // function createLayout(show, venue, bodyContent) {
  function createLayout(venue, bodyContent) {
    let page = (
      <div>
        <Typography variant="body1">{venue.phone}</Typography>
        <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
      </div>
    );
    setPage(page);
    // return page;
  }

  useEffect(() => {
    let venueAccountId = props?.content
      ?.substring(0, 26)
      .replace('<p>', '')
      .replace('</p>', '');
    let bodyContent = props?.content?.substring(26);
    const response = async () => {
      if (venueAccountId) {
        let user = await Auth.currentSession();
        // let showData = await axios({
        //   method: 'get',
        //   url: `/shows/${data.show_id}`,
        //   headers: { idtoken: user.idToken.jwtToken },
        // });
        // console.log(showData.data[0]);
        // console.log('venueAccountId', venueAccountId);
        let accountVenue = await axios({
          method: 'get',
          url: `/accounts/${venueAccountId}`,
          headers: { idtoken: user.idToken.jwtToken },
        });
        // await setPage(createLayout(showData.data[0], accountVenue.data[0]));
        await createLayout(accountVenue.data[0], bodyContent);
      }
    };

    if (venueAccountId) {
      try {
        response().catch((error) => console.log(error));
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [props]);

  return page;
  // return venue;
}
